<template>
  <div v-if="typeSelected === 'flow' || typeSelected === 'pressure'">
    <div class="alarmsLine">
      <!-- <input type="checkbox" name="fordCheckBox" id="cable-connection-warning" value="cable-connection-warning" v-model="checkedNamesAlarms">
        <label for="cable-connection-warning"> 
          <div v-if="checkedNamesAlarms.includes('cable-connection-warning')">
            <img src="../assets/pressure_On.png" width="40" height="40" style="cursor: pointer">
          </div> 
          <div v-else>
            <img src="../assets/pressure_Off.png" width="40" height="40" style="cursor: pointer" >
          </div>
        </label> -->
      <div v-if="typeSelected === 'flow' || typeSelected === 'pressure'">
        <input type="checkbox" name="fordCheckBox2" id="3" value="3" v-model="checkedNamesAlarms">
        <label v-tooltip.right="'Corte de Cabo'" for="3">
          <!-- Corte de Cabo -->
          <div v-if="checkedNamesAlarms.includes('3')">
            <img src="../assets/alarmes/icone_corte_de_cabo_vermelho.png" width="40" height="40"
              style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_corte_de_cabo.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_corte_de_cabo.png" width="40" height="40" style="cursor: pointer">
      </div>
      <div v-if="typeSelected === 'flow'">
        <input type="checkbox" name="fordCheckBox3" id="6" value="6" v-model="checkedNamesAlarms">
        <label v-tooltip.top="'Vazamento Interno'" for="6">
          <!-- Vazamento Interno -->
          <div v-if="checkedNamesAlarms.includes('6')">
            <img src="../assets/alarmes/icone_vazamento_interno_vermelho.png" width="40" height="40"
              style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_vazamento_interno.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_vazamento_interno.png" width="40" height="40" style="filter: opacity(0.2);">
      </div>
      <div v-if="typeSelected === 'flow'">
        <input type="checkbox" name="fordCheckBox4" id="5" value="5" v-model="checkedNamesAlarms">
        <label v-tooltip.top="'Hidrômetro Parado'" for="5">
          <!-- Hidro Parado -->
          <div v-if="checkedNamesAlarms.includes('5')">
            <img src="../assets/alarmes/icone_hdro_parado_vermelho.png" width="40" height="40" style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_hdro_parado.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_hdro_parado.png" width="40" height="40" style="filter: opacity(0.2);">
      </div>
    </div>
    <div class="alarmsLine">
      <div v-if="typeSelected === 'flow'">
        <input type="checkbox" name="fordCheckBox5" id="2" value="2" v-model="checkedNamesAlarms">
        <label v-tooltip.right="'Sensor Removido'" for="2">
          <!-- Sensor Removido -->
          <div v-if="checkedNamesAlarms.includes('2')">
            <img src="../assets/alarmes/icone_sensor_removido_vermelho.png" width="40" height="40"
              style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_sensor_removido.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_sensor_removido.png" width="40" height="40" style="filter: opacity(0.2);">
      </div>
      <div v-if="typeSelected === 'flow'">
        <input type="checkbox" name="fordCheckBox5" id="4" value="4" v-model="checkedNamesAlarms">
        <label v-tooltip.top="'Fluxo Reverso'" for="4">
          <!-- Fluxo Reverso -->
          <div v-if="checkedNamesAlarms.includes('4')">
            <img src="../assets/alarmes/icone_fluxo_reverso_vermelho.png" width="40" height="40"
              style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_fluxo_reverso.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_fluxo_reverso.png" width="40" height="40" style="filter: opacity(0.2);">
      </div>
      <!-- <input type="checkbox" name="fordCheckBox5" id="reverse-leakage" value="reverse-leakage" v-model="checkedNamesAlarms">
        <label for="reverse-leakage">
          <div v-if="checkedNamesAlarms.includes('reverse-leakage')">
            <img src="../assets/noise_On.png" width="40" height="40" style="cursor: pointer">
          </div> 
          <div v-else>
            <img src="../assets/noise_Off.png" width="40" height="40" style="cursor: pointer" >
          </div>
        </label> -->
      <div v-if="typeSelected === 'flow'">
        <input type="checkbox" name="fordCheckBox5" id="1" value="1" v-model="checkedNamesAlarms">
        <label v-tooltip.top="'Tampering'" for="1">
          <div v-if="checkedNamesAlarms.includes('1')">
            <img src="../assets/alarmes/icone_imã_vermelho.png" width="40" height="40" style="cursor: pointer">
          </div>
          <div v-else>
            <img src="../assets/alarmes/icone_imã.png" width="40" height="40" style="cursor: pointer">
          </div>
        </label>
      </div>
      <div v-else>
        <img src="../assets/alarmes/icone_imã.png" width="40" height="40" style="filter: opacity(0.2);">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "FiltroAlarmes",
  emits: ['FiltraAlarmes'],
  props: {
    checkTypeSelect: String
  },
  data() {
    return {
      checkedNamesAlarms: [],
      typeSelected: ''
    }
  },
  methods: {
    checkSensorPress() {
      this.$emit('FiltraAlarmes', this.checkedNamesAlarms)
    },
    checkPressCard(aux) {
      this.checkedNamesAlarms = [`${aux}`];
      this.$emit('FiltraAlarmes', this.checkedNamesAlarms);
    },
    resetFilter() {
      this.checkedNamesAlarms = [];
    }
  },
  watch: {
    checkedNamesAlarms: function () {
      this.checkSensorPress();
    },
    checkTypeSelect: function () {
      this.typeSelected = this.checkTypeSelect;
    }
  },
  created() {
    this.emitter.on('select-alarmes', (data) => {
      let aux = [];
      aux = data;
      this.checkPressCard(aux)
    });

    this.emitter.on('reset-checked-alarms', () => {
      this.resetFilter();
    });

    this.emitter.on('filter-reset', () => {
      this.resetFilter();
    })
  },
}
</script>

<style scoped>
input[type=checkbox] {
  display: none;
}

.alarmsLine {
  display: flex;
  justify-content: space-evenly;
}

input[type=checkbox]+label {
  display: inline-block;
  padding: 0 0 0 0px;
  height: 50px;
  width: 50px;
  ;
  background-size: 100%;
}

input[type=checkbox]:checked+label {
  height: 50px;
  width: 50px;
  display: inline-block;
  background-size: 100%;
}
</style>