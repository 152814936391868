<template>
<Toast />
  <body>
    <div class="groupCadastros">
      <GroupbtnAdmin></GroupbtnAdmin>
    </div>
    <main>
      <div class="card col-sm-7" id="main_box">
        <div class="card-body">
          <p>Cadastrar Usuário</p>
          <div class="row">
          <p style="font-size:1rem">* Campos Obrigatórios</p>

            <div class="col-sm-6">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                  autocomplete="off"
                  v-model="nome"
                  aria-describedby="addon-wrapping"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">Nome *</label>
              </div>
            </div>
            <div class="col-sm-6 form-floating" v-if="isSuper">
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="funcao"
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="tipoFuncao"
                id="floatingInputGrid"
              />
              <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Função *</label>
            </div>
            <div class="col-sm-6">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="E-mail"
                  v-model="email"
                  aria-describedby="addon-wrapping"
                  autocomplete="off"
                  id="floatingInputGrid"
                />
                <label for="floatingInputGrid">E-mail *</label>
              </div>
            </div>
            <div class="col-sm-6 form-floating" v-if="isSuper == 1">
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="selCliente"
                required
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="listaClientes"
                id="floatingInputGrid"
              />
              <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Clientes *</label>
            </div>
            
            <div class="col-sm-12 form-floating" v-else>
              <Multiselect
                :columns="{ container: 12, label: 4, wrapper: 6 }"
                v-model="funcao"
                placeholder="Selecione"
                class="multiselect-custom form-select"
                :options="tipoFuncao2"
                id="floatingInputGrid"
              />
              <label for="floatingSelect" style="margin-left: 15px;padding: 12px;">Função *</label>
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              v-model="ativo"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Ativo
            </label>
          </div>
          <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button">
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { store } from "../services/store.js";
import GroupbtnAdmin from "../components/groupbtnAdmin.vue";
import { useLoading } from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";
import { createUserNew, listClients } from "../services/crud";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
const $loading = useLoading();
export default {
  components: {
    Multiselect,
    GroupbtnAdmin,
    Toast
  },
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      isSuper: store.type_user == 1 ? true : false,
      ativo: false,
      listClient: "",
      errors: [],
      listaClientes: [],
      selCliente: "",
      nome: "",
      email: "",
      funcao: "",
      tipoFuncao: [
        { label: "Super Usuário", value: 1 },
        { label: "Administrador", value: 2 },
        { label: "Operacional", value: 3 },
      ],
      tipoFuncao2: [{ label: "Administrador", value: 2 },{ label: "Operacional", value: 3 }],
      client_id: "",
    };
  },
  methods: {
    submit: async function () {
      if (!this.nome) {
        this.errors.push(" Nome");
      }
      if (!this.email) {
        this.errors.push(" Email");
      }
      if (!this.funcao) {
        this.errors.push(" Função");
      }
      if (this.isSuper && !this.selCliente){
        this.errors.push(" Clientes")
      }

      if (this.errors.length === 1) {
        this.showErrorInput(this.errors)
      }

      if (this.errors.length > 1) {
        this.showErrorInputs(this.errors)
      }

      if (!this.errors.length) {
        const loader = $loading.show({});

        if (!this.isSuper) {
          this.selCliente = store.client_id;
          console.log('Testes', this.isSuper, this.selCliente)
        }
        const dataForm = {
          name: this.nome,
          email: this.email,
          active: this.ativo,
          type_user: this.funcao,
          client_id: this.selCliente,
        };

        if(!this.nome || !this.nome.trim() || this.isSuper && !this.selCliente.trim() || !this.email || !this.email.trim()){
          this.showError()
        } else {
          await createUserNew(dataForm)
            .then((response) => {
              if (response.status >= 200 && response.status <= 299) {
                this.showsuccess();
                this.nome = "";
                this.email = "";
                this.funcao = "";
                this.ativo = false;
              }
            })
            .catch((err) =>
              this.showErrorServer(err)
            );
        }
        loader.hide();
        return true;
      } else {
        this.errors = [];
        //  this.error();
      }
    }
  },
  async mounted() {
    if (store.type_user) {
      let aux = [];
      let listClient = [];
      await listClients()
        .then((response) => {
          aux = response.data;
          for (let i = 0; i < aux.length; i++) {
            listClient = {
              label: aux[i].trading_name,
              value: aux[i].id,
            };
            this.listaClientes.push(listClient);
          }
        })
        .catch((err) =>
          this.showErrorServer(err)
        );
    }
    if (store.client_id) {
      this.client_id = store.client_id;
    }
  },
  setup(){
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 2500});
    }
    const showErrorServer = (err) => {
      toast.add({severity:'error', summary: 'Error', detail: err, life: 2500});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:"Usuário cadastrado com sucesso! ", life: 2500});
    }
    const showErrorInput = (inputs) => {
      toast.add({severity:'error', detail:"O seguinte campo não foi preenchido corretamente: " + inputs + ".", life: 2500});
    }
    const showErrorInputs = (inputs) => {
      toast.add({severity:'error', detail:"Os seguintes campos não foram preenchidos corretamente: " + inputs + ".", life: 2500});
    }
    return{
      showError,showErrorServer,showsuccess,showErrorInput,showErrorInputs
    }
  }
};
</script>

<style scoped>
p{
  font-size: 0.7rem;
}
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating> .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
p {
  font-size: 1.5rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  background-color: white;
  color: black;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  padding-right: 0.2rem;
  background: #e8f0fe;
}
.multiselect-placeholder {
  color: black !important;
}
.required,
.error {
  color: red;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem;
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>