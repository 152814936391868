<template>
<Toast />
  <h2> Relatórios </h2>
  <div style="display: flex; justify-content:center">
  <div class="tableBox col-sm-6">
    <v-date-picker :model-config="modelConfig" color="green" v-model="date_start" class="inputClass"> 
      <template v-slot="{ inputValue, inputEvents }" >
        <div class="textDate">De:</div>

        <input
          style="height:2.5rem; width: 100%;"
          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
          :value="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <v-date-picker :model-config="modelConfig" color="green" v-model="date_end" :max-date="disableDate()" class="inputClass">
      <template v-slot="{ inputValue, inputEvents }" >
        <div class="textDate">Até:</div>
        <input
          style="height:2.5rem; width: 100%;"
          class="border rounded focus:outline-none focus:border-blue-300"
          :value="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <!-- <v-date-picker v-model="date" :model-config="modelConfig" mode="date" is24hr color="green" :max-date="date_start + 30 * 24 * 3600 * 1000">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-center items-center">
          <div class="textDate">Data:</div>
          <input
            style="height:2.5rem; width: 100%; margin-bottom: 1rem"
            :value="inputValue.start"
            v-on="inputEvents.start"
            :class="this.selectedCountries.name == ''?'border px-2 w-32 rounded focus:outline-none focus:border-indigo-300 input-data ':'border px-2  w-32 rounded focus:outline-none focus:border-indigo-300 input-data hideInput'" />
          <div>
            <div class="textDate">Até:</div>
            <input
              :value="inputValue.end" v-on="inputEvents.end"
              class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            />
          </div>
        </div>
      </template>
    </v-date-picker> -->

    <div class="inputClass">
      <label
        for="floatingSelect"
        >Tipo do Dispositivo:
      </label>
      <Multiselect
        :columns="{ container: 12, label: 4, wrapper: 6 }"
        v-model="tipo"
        placeholder="Selecione"
        class="multiselect-custom form-select"
        :options="tiposDispositivos"
        id="floatingInputGrid"
      />
    </div>

    <!-- <div class="inputClass">
      <label
        for="floatingSelect"
        >Tipo de Dados
      </label>
      <Multiselect
        :columns="{ container: 12, label: 4, wrapper: 6 }"
        v-model="tipoDado"
        placeholder="Selecione"
        class="multiselect-custom form-select"
        :options="tipoDadosOptions"
        id="floatingInputGrid"
        noOptionsText= "Selecione o Tipo do Dispositivo"
      />
    </div> -->

    <button v-if="!json_data" class="btn btn-filtrar" @click="filtrarReq()">Filtrar</button>
    
    <div v-else>
      <xlsx-workbook @created="onCreated">
        <xlsx-sheet
          :collection="json_data"
          :sheet-name = sheetNameConjunto
        />
        <xlsx-download :filename="'Relatório_' + sheetNameConjunto + '.xlsx'" >
          <button class="btn btn-filtrar">Exportar XLSX</button>
        </xlsx-download>
        
      </xlsx-workbook>

      <button class="btn btn-filtrar" @click="exportCSV()">Exportar CSV</button>

      <button class="btn btn-filtrar" @click="limparBusca()">Nova Pesquisa</button>
    
    </div>
  </div>
  </div>
</template>

<script>
import "v-calendar/dist/style.css";
import Multiselect from "@vueform/multiselect";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue3-xlsx";
import fileSaver from 'file-saver'
import jsonexport from 'jsonexport'
import {  relatorio } from "../services/dashboard.js";
import { store } from "../services/store"
import {useLoading} from 'vue-loading-overlay'
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";

const $loading = useLoading()

export default {
  components: {
    Multiselect,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    Toast
  },
  data() {
    return{
      json_data:null,
      sheetNameConjunto: '',
      selectedCountries: '',
      date_start: this.aMonthAgo(),
      date_end: new Date(),
      tipoDado: '',
      tipo: '',
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        timeAdjust: "12:00:00",
      },
      tiposDispositivos: [
        { value: "flow", label: "Vazão" },
        { value: "pressure", label: "Pressão" }
        // { value: "noise", label: "Ruído" },
      ]      
    }
  },
  watch: {
    tipo: function(){
      this.json_data = null
    },
    date_start: function(){
      this.json_data = null
    },
    date_end: function(){
      this.json_data = null
    }
  },
  //   tipo: function () {
  //     if (this.tipo == "flow") {
  //       this.tipoDadosOptions = [
  //         {value: "a", label: "a"},
  //         {value: "b", label: "b"},
  //         {value: "c", label: "c"}
  //       ]
  //     } else if (this.tipo == "pressure") {
  //       this.tipoDadosOptions = [
  //         {value: "d", label: "d",},
  //         {value: "e", label: "e",},
  //         {value: "f", label: "f",}
  //       ]
  //     } else if (this.tipo == "noise") {
  //       this.tipoDadosOptions = [
  //         {value: "g", label: "g",},
  //         {value: "h", label: "h",},
  //         {value: "i", label: "i",}
  //       ]
  //     }
  //   }
  // },
  methods: {
    limparBusca() {
      this.json_data = null
      this.date_start= this.aMonthAgo()
      this.date_end= new Date()
      this.tipo= ''
    },
    aMonthAgo() {
      const dateStart = new Date();
      return dateStart.getTime() - 30 * 24 * 3600 * 1000;
    },
    disableDate() {
      let varInit = this.date_start
      if(typeof varInit === "number"){
        this.date_start + 30 * 24 * 3600 * 1000
      } else {
        let num = this.date_start
        let string = num.toString()
        var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        var dt = new Date(string.replace(pattern,'$3-$2-$1'));
        dt.setMonth(dt.getMonth()+1)
      }
      return dt
    },
    exportCSV() {
      const loader = $loading.show({});

      return new Promise((resolve, reject) => {
        jsonexport(this.json_data, { forceTextDelimiter: true }, (err, csv) => {
          if(err) {
            this.showErrorServer(err)
            return reject(err)
          }
          let filename = 'Relatório_' + this.sheetNameConjunto + '.csv'
          let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
          fileSaver.saveAs(blob, filename)
          resolve(true)
          loader.hide()
        })
      })
    },
    async filtrarReq (){
      let data = {
        "from": this.date_start,
        "to": this.date_end,
        "type": this.tipo,
        "client_id": store.client_id        
      };
      this.sheetNameConjunto = this.tipo==='flow' ? 'Vazão' + this.date_start + "_" + this.date_end : 'Pressão' + this.date_start + "_" + this.date_end 
      let relatorioXlsx = []
      let products = ''
        
      let date = new Date(this.date_start);
      let dt_start = date.toDateString();
      
      var timeDiff = Math.abs(Date.parse(this.date_end) - Date.parse(dt_start));
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
      
      if(this.tipo === ''){
        this.showErrorDevice()
      }else if(diffDays > 31){        
        this.showErrorDate()
      }else{
      const loader = $loading.show({});
      await relatorio(data)
      .then((response) => {
        let data = response.data.data;
        if(this.tipo === 'flow'){
          this.json_data = data.map(json_info =>
            ({
              'Código de Instalação': json_info.name,
              'ID do Dispositivo':json_info.serial_number,
              'Data/Hora': json_info.date_time,
              'Vazão': json_info.info.flow,
              'Volume Acumulado': json_info.info.accumulatedVolume
            }))
        } else {
          this.json_data = data.map(json_info => 
            ({
              'Código de Instalação': json_info.id,
              'ID do Dispositivo':json_info.serial_number,
              'Data/Hora': json_info.date_time,
              'Pressão':json_info.info.single_value
              
            })
          )
        }
      })
      .catch((err) => this.showErrorServer(err)
      );
      loader.hide()

      return {products, relatorioXlsx}
      }      
    }
  },
  setup(){
    const toast = useToast();
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 2500});
    }
    const showErrorDevice = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Selecione o tipo do dispositivo! " , life: 2500});
    }
    const showErrorDate = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Selecione um período com no máximo 30 dias! " , life: 2500});
    }
    return {showErrorServer,showErrorDevice,showErrorDate}
  }
}
</script>

<style scoped>
h2{
  margin: 1rem
}
.tableBox{
  padding: 1rem 2rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 100%;
  font-weight: 400;
  height: 42px;
}
.inputClass{
  margin: 1rem 0rem;
  width: 100%
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-caret{
    margin-right: -1rem;
  }
}
</style>