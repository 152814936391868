import { store } from './store.js'
import api from './api.js'
import { routes } from "../services/routes";

const getHeader = () => ({
    headers: {
        'Authorization': `Bearer ${store.token}`,
        'Content-Type': 'application/json'
    }
})

//Users
export const createUser = ({ name, email, active, client_id }) => {
    return api.post(routes.kdi.users(), { name, email, active, client_id }, getHeader())
}

export const createUserNew = ({ name, email, active, client_id, type_user }) => {
    return api.post(routes.kdi.usersNew(), { name, email, active, client_id, type_user }, getHeader())
}

export const upDateUser = ({ id, name, email, active, client_id, type_user }) => {
    return api.put(routes.kdi.users(), { id, name, email, active, client_id, type_user }, getHeader())
}

export const deleteUser = (id) => {
    return api.delete(routes.kdi.users(), {id}, getHeader())
}

export const listUsers = () => {
    let json = api.get(routes.kdi.usersAll(), getHeader())
    return json
}

export const listOneUser = (id) => {
    let json = api.post(routes.kdi.usersFilter(), {id}, getHeader())
    return json
}

export const filterUser = (client_id) => {
    let json = api.post(routes.kdi.usersFilter(), {client_id}, getHeader())
    return json
}

//Devices
export const createDevice = ({ id, serial_number, type, user_id, client_id, tags, setup, version }) => {
    return api.post(routes.kdi.devices(), {
        id,
        serial_number,
        type,
        user_id,
        client_id,
        tags,
        setup,
        version
    }, getHeader())
}

export const upDateDevice = ({ id, serial_number, type, tags, user_id, client_id, setup, version }) => {
    return api.patch(routes.kdi.devices(), {
        id,
        serial_number,
        type,
        tags,
        user_id,
        client_id,
        setup,
        version
    }, getHeader())
}

export const deleteDevice = (id) => {
    return api.delete(routes.kdi.devices(), {id}, getHeader())
}

export const getDevice = (id) => {
    return api.post(routes.kdi.devices(), id, getHeader())
}

export const listDevices = () => {
    return api.get(routes.kdi.devicesAll(), getHeader())
}

export const listOneDevice = (value) => {
    return api.post(routes.kdi.devicesFilter(), value, getHeader())
}

export const filterDevice = (data) => {
    return api.post(routes.kdi.devicesFilter(), data, getHeader())
}

//Clients
export const createClient = ({ corporate_name, address, number, complement, city, neighborhood, state, zip_code, comments, contact, telephone, lat, long, cnpj, client_id_master, active, trading_name, active_ada }) => {
    return api.post(routes.kdi.clients(), { corporate_name, address, number, complement, city, neighborhood, state, zip_code, comments, contact, telephone, lat, long, cnpj, client_id_master, active, trading_name, active_ada }, getHeader())
}

export const upDateClient = ({ id, corporate_name, address, number, complement, city, neighborhood, state, zip_code, comments, contact, telephone, lat, long, cnpj, client_id_master, active, trading_name, active_ada }) => {
    return api.patch(routes.kdi.clients(), { id, corporate_name, address, number, complement, city, neighborhood, state, zip_code, comments, contact, telephone, lat, long, cnpj, client_id_master, active, trading_name, active_ada }, getHeader())
}

export const deleteClient = (id) => {
    return api.delete(routes.kdi.clients(), {id}, getHeader())
}

export const listClients = () => {
    return api.get(routes.kdi.clientsAll(), getHeader())
}

export const listOneClient = (id) => {
    return api.post(routes.kdi.clientsFilter(), {id}, getHeader())
}

export const filterClient = (client_id) => {
    return api.post(routes.kdi.clientsFilter(), {client_id}, getHeader())
}

//Comissioning

export const createComissioning = ({ number, city, state, neighborhood, complement, zipcode, address, country, meter, initial_display, serial_number_flow, serial_number_noise, serial_number_pressure, lat, long, client_id, user_id, resolution, display_digits, display_res, images }) => {
    return api.post(routes.kdi.managerCommission(), { number, city, state, neighborhood, complement, zipcode, address, country, meter, initial_display, serial_number_flow, serial_number_noise, serial_number_pressure, lat, long, client_id, user_id, resolution, display_digits, display_res, images }, getHeader())
}

export const listComissioning = () => {
    return api.get(routes.kdi.meterDeviceInstallPointAll(), getHeader())
}
export const listComissioningFilter = (data) => {
    return api.post(routes.kdi.meterDeviceInstallPointFilterClient(), data, getHeader())
}

export const upDateComissioning = ({ install_point_id, device_id, active, setup, end_time, obs }) => {
    const requestData = active === false ? { install_point_id, device_id, active, setup, end_time, obs } : { install_point_id, device_id, active, setup, obs };
    return api.patch(
        routes.kdi.meterDeviceInstallPoint(),
        requestData,
        getHeader()
    );
}

export const listClientComissioning = (client_id) => {
    return api.post(routes.kdi.public.devicesInstallPoints(), client_id, getHeader())
}


//Istall Points
export const listInstallPoints = () => {
    return api.get(routes.kdi.installPointsAll(), getHeader())
}

//Schema
export const listSchema = (client_id) => {
    return api.post(routes.kdi.deviceSchemaFilter(), client_id, getHeader())
}

//Tags
export const createTag = (data) => {
    return api.post(routes.kdi.tags(), data, getHeader())
}
export const deleteTag = (data) => {
    return api.delete(routes.kdi.tags(), { headers: { 'Authorization': `Bearer ${store.token}`, }, data });
}
export const listTag = (id_client) => {
    return api.post(routes.kdi.tagsFilter(), id_client , getHeader())
}

export const ativation = (data) => {
    return api.post(routes.kdi.devicesMigration(), data, getHeader())
}
export default {
    listTag,
    createTag,
    deleteTag,
    createUser,
    upDateUser,
    deleteUser,
    listUsers,
    listOneUser,
    filterUser,
    createDevice,
    upDateDevice,
    deleteDevice,
    listDevices,
    listOneDevice,
    filterDevice,
    createClient,
    upDateClient,
    deleteClient,
    listClients,
    listSchema,
    listOneClient,
    filterClient,
    listComissioning,
    upDateComissioning,
    listInstallPoints,
    listComissioningFilter,
    ativation,
    getDevice
}