<template>
  <div>
    <div v-for="dev in this.listDevices" :key="dev" id="div1">
      <div v-if="testLoad">
        <div class="card" style="border-radius:15px">
          <div class="grid formgrid">
            <div class="field col-12 md:col-6 md:pr-6 pr-0">
              <div class="custom-skeleton p-4">
                <Skeleton width="100%" height="250px"></Skeleton>
                <div class="flex justify-content-center mt-3">
                  <Skeleton width="100%" height="80px"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="margin-top: 2rem; border-radius:15px">
          <div class="grid formgrid">
            <div class="field col-12 md:col-6 md:pr-6 pr-0">
              <div class="custom-skeleton p-4">
                <Skeleton width="100%" height="250px"></Skeleton>
                <div class="flex justify-content-center mt-3">
                  <Skeleton width="100%" height="80px"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="dev.type == 'flow' && this.amountDataFlow > 0" class="charts">
          <ChartPeriodButton :nameIdPrefix="flow" :selected="this.periodos.flow"
            :onChange="(period) => this.setPeriod('flow', period)" />
          <div v-if="this.periodos.flow in this.chartOptions.flow">
            <highcharts v-if="dev.type == 'flow'" class="hc" :constructorType="'stockChart'"
              :options="this.chartOptions.flow[this.periodos.flow]" ref="chart">
            </highcharts>
          </div>
          <div class="hidden smaller"></div>
        </div>
        <div v-if="dev.type == 'flow' && this.amountDataVolume > 0" class="charts">
          <ChartPeriodButton :nameIdPrefix="volume" :selected="this.periodos.volume"
            :onChange="(period) => this.setPeriod('volume', period)" />
          <div v-if="this.periodos.volume in this.chartOptions.volume">
            <highcharts class="hc" :constructorType="'stockChart'"
              :options="this.chartOptions.volume[this.periodos.volume]" :redrawOnUpdate="true" ref="chart">
            </highcharts>
          </div>
          <div class="hidden smaller"></div>
        </div>
        <div v-if="dev.type == 'pressure' && this.amountDataPressure > 0" class="charts">
          <ChartPeriodButton :nameIdPrefix="pressure" :selected="this.periodos.pressure"
            :onChange="(period) => this.setPeriod('pressure', period)" />
          <div v-if="this.periodos.pressure in this.chartOptions.pressure">
            <highcharts class="hc" :options="this.chartOptions.pressure[this.periodos.pressure]"
              :constructorType="'stockChart'" :redrawOnUpdate="true" ref="chart">
            </highcharts>
          </div>
          <div class="hidden smaller"></div>
        </div>
        <div v-if="dev.type == 'noise' && this.amountDataNoise > 0" class="charts">
          <ChartPeriodButton :nameIdPrefix="noise" :selected="this.periodos.noise"
            :onChange="(period) => this.setPeriod('noise', period)" />
          <div v-if="this.periodos.noise in this.chartOptions.noise">
            <highcharts v-if="dev.type == 'noise'" class="hc" :constructorType="'stockChart'"
              :options="this.chartOptions.noise[this.periodos.noise]" :redrawOnUpdate="true" ref="chart">
            </highcharts>
          </div>
          <div class="hidden smaller"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Chart } from 'highcharts-vue'
import Highcharts from "highcharts";
import HighchartsNoData from 'highcharts/modules/no-data-to-display'
import stockInit from "highcharts/modules/stock";
import highchartsMore from "highcharts/highcharts-more";
import { readingsCharts } from "../services/dashboard.js";
import { store } from '@/services/store.js';
import { useToast } from "primevue/usetoast";
import Skeleton from 'primevue/skeleton';
import ChartPeriodButton from "@/components/ChartPeriodButton.vue";

stockInit(Highcharts)
highchartsMore(Highcharts)
HighchartsNoData(Highcharts)

Highcharts.setOptions({
  lang: {
    noData: 'Não há dados para exibição',
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    weekdays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    loading: ['Atualizando o gráfico...aguarde'],
    contextButtonTitle: 'Exportar gráfico',
    decimalPoint: ',',
    thousandsSep: '.',
    downloadJPEG: 'Baixar imagem JPEG',
    downloadPDF: 'Baixar arquivo PDF',
    downloadPNG: 'Baixar imagem PNG',
    downloadSVG: 'Baixar vetor SVG',
    printChart: 'Imprimir gráfico',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Para',
    rangeSelectorZoom: 'Zoom',
    resetZoom: 'Limpar Zoom',
    resetZoomTitle: 'Voltar Zoom para nível 1:1',
    maximum: 'Maximo',
  }
});

export default {
  name: 'Charts',
  components: {
    ChartPeriodButton,
    Skeleton,
    highcharts: Chart
  },
  props: {
    dataInicial: String,
    dataFinal: String,
    selectedDevice: Object,
    upGrafico: Number,
  },
  data() {
    return {
      testLoad: null,
      widthFlow: null,
      listDevices: [],
      readingsFlow: [],
      readingsVolume: [],
      readingsPressure: [],
      readingsNoise: [],
      qtdePressure: 0,
      amountDataFlow: 0,
      amountDataVolume: 0,
      amountDataPressure: 0,
      amountDataNoise: 0,
      alarmFlow: {},
      alarmPressure: {},
      local: null,
      periodos: {
        volume: 'hourly',
        flow: 'hourly',
        pressure: 'hourly',
        noise: 'hourly',
      },
      chartOptions: {
        volume: {
          hourly: {
            yAxis: {
              title: {
                text: 'Volume (m³/H)'
              }
            },
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Volume'
            },
            series: [{
              name: 'Volume',
              type: 'spline',
              tooltip: {
                valueDecimals: 2
              }
            }]
          },
          daily: {
            yAxis: {
              title: {
                text: 'Volume (m³/H)'
              }
            },
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Volume'
            },
            series: [{
              name: 'Volume',
              type: 'spline',
              tooltip: {
                valueDecimals: 2
              }
            }]
          },
          weekly: {
            yAxis: {
              title: {
                text: 'Volume (m³/H)'
              }
            },
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Volume'
            },
            series: [{
              name: 'Volume',
              type: 'spline',
              tooltip: {
                valueDecimals: 2
              }
            }]
          },
        },
        flow: {
          hourly: {
            title: {
              text: 'Vazão'
            },
            rangeSelector: {
              selected: 2
            },
            tooltip: {
              pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
            },
            yAxis: [{
              title: {
                text: 'Vazão (m³/h)'
              }
            }],
            series: [{
              type: 'column',
              name: 'Vazão (m³/h)',
              color: '#6EFF94'
            }]
          },
          daily: {
            chart: {
              type: 'column',
              zoomType: 'xy'
            },
            title: {
              text: 'Vazão'
            },
            rangeSelector: {
              selected: 1,
              enabled: true
            },
            yAxis: {
              title: {
                text: 'Vazão'
              }
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0
              }
            },
            series: [{
              type: 'column',
              name: 'Vazão (m³/h)',
              color: '#6EFF94'
            }]
          },
          weekly: {
            chart: {
              type: 'column',
              zoomType: 'xy'
            },
            title: {
              text: 'Vazão'
            },
            rangeSelector: {
              selected: 1,
              enabled: true
            },
            yAxis: {
              title: {
                text: 'Vazão'
              }
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0
              }
            },
            series: [{
              type: 'column',
              name: 'Vazão (m³/h)',
              color: '#6EFF94'
            }]
          },
        },
        pressure: {
          hourly: {
            yAxis: {
              title: {
                text: 'Pressão'
              }
            },
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Pressão'
            },
            series: [{
              name: 'Pressão',
              type: 'spline',
              tooltip: {
                valueDecimals: 2
              }
            }]
          },
          daily: {
            type: 'boxplot',
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Pressão'
            },
            legend: {
              enabled: false
            },
            series: [{
              type: 'boxplot',
              name: 'Pressão',
            }]
          },
          weekly: {
            type: 'boxplot',
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Pressão'
            },
            legend: {
              enabled: false
            },
            series: [{
              type: 'boxplot',
              name: 'Pressão'
            }]
          },
        },
        noise: {
          hourly: {
            yAxis: {
              title: {
                text: 'Ruído'
              }
            },
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Ruído'
            },
            series: [{
              name: 'Ruído',
              type: 'spline',
              tooltip: {
                valueDecimals: 2
              }
            }]
          },
          daily: {
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Ruído'
            },
            series: [{
              type: 'candlestick',
              name: 'Ruído'
            }]
          },
          weekly: {
            rangeSelector: {
              enabled: false
            },
            title: {
              text: 'Ruído'
            },
            series: [{
              type: 'candlestick',
              name: 'Ruído'
            }]
          }
        }
      },
    };
  },
  watch: {
    readingsFlow: async function () {
      this.testLoad = true;
      this.redrawFlow();
      await this.createdList();
    },
    readingsPressure: function () {
      this.testLoad = true;
      this.redrawPressure();
    },
    readingsNoise: function () {
      this.testLoad = true;
      this.redrawNoise();

    },
    selectedDevice: async function () {
      this.resett()
      await this.createdList();
      await this.getData();
    },
    async upGrafico() {
      await this.getData();
    },
  },
  methods: {
    setPeriod: function (chartType, period) {
      this.periodos[chartType] = null;
      setTimeout(() => {
        this.periodos[chartType] = period;
      }, 50); // Hack to re-draw component @TODO - find the correct way to do this update
    },
    resett: function () {
      this.testLoad = true
    },
    redrawFlow: function () {
      const legendaFinal = [];
      const dateFinal = [];
      const dateFinalDaily = [];
      const dateFinalWeekly = [];

      var dataVolumeHourly = (this.readingsVolume && this.readingsVolume.cum_hourly) ? this.readingsVolume.cum_hourly : [];
      var dataVolumeDaily = (this.readingsVolume && this.readingsVolume.cum_daily) ? this.readingsVolume.cum_daily : [];
      var dataVolumeWeekly = (this.readingsVolume && this.readingsVolume.cum_weekly) ? this.readingsVolume.cum_weekly : [];

      var dataFlowHourly = (this.readingsFlow && this.readingsFlow.hourly) ? this.readingsFlow.hourly : [];
      var dataFlowDaily = (this.readingsFlow && this.readingsFlow.daily) ? this.readingsFlow.daily : [];
      var dataFlowWeekly = (this.readingsFlow && this.readingsFlow.weekly) ? this.readingsFlow.weekly : [];
      var dataAlarmHourly = (this.readingsFlow && this.readingsFlow.alarm_hourly) ? this.readingsFlow.alarm_hourly : [];
      var dataAlarmDaily = (this.readingsFlow && this.readingsFlow.alarm_daily) ? this.readingsFlow.alarm_daily : [];
      var dataAlarmWeekly = (this.readingsFlow && this.readingsFlow.alarm_weekly) ? this.readingsFlow.alarm_weekly : [];

      this.amountDataVolume = 1;
      this.amountDataFlow = 1;

      if (dataFlowHourly.length > 0 && dataFlowHourly[0].length > 0) {
        let total = dataFlowHourly[0].length;

        for (let index = 0; index < total; index++) {
          let legenda = dataFlowHourly[0][index];
          let hourly = dataFlowHourly[1][index];

          if (dataAlarmHourly && dataAlarmHourly.length > 0) {
            if (dataAlarmHourly.find(alarm => alarm == legenda)) {
              dateFinal.push({ x: legenda, y: hourly, color: '#80E29C' });
            } else {
              dateFinal.push({ x: legenda, y: hourly, color: '#6EFF94' });
            }
          } else {
            dateFinal.push({ x: legenda, y: hourly, color: '#6EFF94' });
          }

          legendaFinal.push(legenda);
        }
      }

      if (dataFlowDaily.length > 0 && dataFlowDaily[0].length > 0) {
        let total = dataFlowDaily.length;
        for (let index = 0; index < total; index++) {
          let legenda = dataFlowDaily[index][0];
          let daily = dataFlowDaily[index][5];

          if (dataAlarmDaily && dataAlarmDaily.length > 0) {
            if (dataAlarmDaily.find(alarm => alarm == legenda)) {
              dateFinalDaily.push({ x: legenda, y: daily, color: 'red' });
            } else {
              dateFinalDaily.push({ x: legenda, y: daily, color: '#6EFF94' });
            }
          } else {
            dateFinalDaily.push({ x: legenda, y: daily, color: '#6EFF94' });
          }

          legendaFinal.push(legenda);
        }
      }

      if (dataFlowWeekly.length > 0 && dataFlowWeekly[0].length > 0) {
        let total = dataFlowWeekly.length;

        for (let index = 0; index < total; index++) {
          let legenda = dataFlowWeekly[index][0];
          let weekly = dataFlowWeekly[index][5];

          if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
            if (dataAlarmWeekly.find(alarm => alarm == legenda)) {
              dateFinalWeekly.push({ x: legenda, y: weekly, color: 'red' });
            } else {
              dateFinalWeekly.push({ x: legenda, y: weekly, color: '#6EFF94' });
            }
          } else {
            dateFinalWeekly.push({ x: legenda, y: weekly, color: '#6EFF94' });
          }

          legendaFinal.push(legenda);
        }
      }
      this.testLoad = false;
      this.chartOptions.flow.hourly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          type: 'column',
          zoomType: 'xy'
        },
        rangeSelector: {
          selected: 1,
          enabled: false
        },
        title: {
          text: 'Vazão'
        },
        tooltip: {
          pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
        },
        yAxis: {
          title: {
            text: 'Vazão (m³/h)'
          },
          plotLines: [{
            value: this.alarmFlow.lower_limit,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: 'Valor mínimo'
            },
            zIndex: 10
          }, {
            value: this.alarmFlow.upper_limit,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              align: 'right',
              text: 'Valor máximo',
              x: -40
            },
            zIndex: 10
          }]
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          type: 'column',
          name: 'Vazão (m³/h)',
          data: dateFinal,
          color: '#6EFF94'
        }]
      }

      this.chartOptions.flow.daily = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          type: 'column',
          zoomType: 'xy'
        },
        title: {
          text: 'Vazão'
        },
        rangeSelector: {
          selected: 1,
          enabled: false
        },
        tooltip: {
          pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
        },
        yAxis: [{
          title: {
            text: 'Vazão (m³/h)'
          }
        }],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          type: 'column',
          name: 'Vazão (m³/h)',
          data: dateFinalDaily,
          color: '#6EFF94'
        }]
      }

      this.chartOptions.flow.weekly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          type: 'column',
          zoomType: 'xy'
        },
        title: {
          text: 'Vazão'
        },
        rangeSelector: {
          selected: 1,
          enabled: false
        },
        tooltip: {
          pointFormat: 'Vazão: <b>{point.y} m³/h</b>'
        },
        yAxis: [{
          title: {
            text: 'Vazão (m³/h)'
          }
        }],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          type: 'column',
          name: 'Vazão (m³/h)',
          data: dateFinalWeekly,
          color: '#6EFF94'
        }]
      }

      const legendaFinalVolumeHourly = [];
      const dateFinalVolumeHourly = [];
      const legendaFinalVolumeDaily = [];
      const dateFinalVolumeDaily = [];
      const legendaFinalVolumeWeekly = [];
      const dateFinalVolumeWeekly = [];

      if (dataVolumeHourly.length > 0) {
        let total = dataVolumeHourly.length;
        for (let index = 0; index < total; index++) {
          let hourlyVolume = dataVolumeHourly[index];
          dateFinalVolumeHourly.push(hourlyVolume);
          legendaFinalVolumeHourly.push(hourlyVolume[0]);
        }
      }

      if (dataVolumeDaily.length > 0) {
        let total = dataVolumeDaily.length;
        for (let index = 0; index < total; index++) {
          let hourlyVolume = dataVolumeDaily[index];
          dateFinalVolumeDaily.push(hourlyVolume);
          legendaFinalVolumeDaily.push(hourlyVolume[0]);
        }
      }

      if (dataVolumeWeekly.length > 0) {
        let total = dataVolumeWeekly.length;
        for (let index = 0; index < total; index++) {
          let hourlyVolume = dataVolumeWeekly[index];
          dateFinalVolumeWeekly.push(hourlyVolume);
          legendaFinalVolumeWeekly.push(hourlyVolume[0]);
        }
      }

      this.chartOptions.volume.hourly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          zoomType: 'x'
        },
        yAxis: {
          title: {
            text: 'Volume (m³)'
          }
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Volume'
        },
        xAxis: {
          categories: legendaFinalVolumeHourly
        },
        tooltip: {
          pointFormat: 'Volume: <b>{point.y} m³</b>'
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              valueDecimals: 2
            }
          }
        },
        series: [{
          marker: {
            enabled: true
          },
          name: 'Volume',
          data: dateFinalVolumeHourly,
          type: 'spline',
          tooltip: {
            valueDecimals: 2
          }
        }]
      }

      this.chartOptions.volume.daily = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          zoomType: 'x'
        },
        yAxis: {
          title: {
            text: 'Volume (m³)'
          }
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Volume'
        },
        xAxis: {
          categories: legendaFinalVolumeDaily,
        },
        tooltip: {
          pointFormat: 'Volume: <b>{point.y} m³</b>'
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              valueDecimals: 2
            }
          }
        },
        series: [{
          marker: {
            enabled: true
          },
          name: 'Volume',
          data: dateFinalVolumeDaily,
          type: 'spline',
          tooltip: {
            valueDecimals: 2
          }
        }]
      }

      this.chartOptions.volume.weekly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          zoomType: 'x'
        },
        yAxis: {
          title: {
            text: 'Volume (m³)'
          }
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Volume'
        },
        xAxis: {
          categories: legendaFinalVolumeWeekly
        },
        tooltip: {
          pointFormat: 'Volume: <b>{point.y} m³</b>'
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              valueDecimals: 2
            }
          }
        },
        series: [{
          marker: {
            enabled: true
          },
          name: 'Volume',
          data: dateFinalVolumeWeekly,
          type: 'spline',
          tooltip: {
            valueDecimals: 2
          }
        }],
      }
    },
    redrawPressure: function () {
      const dataAlarmDaily = this?.readingsPressure?.alarm_daily || [];
      const dataAlarmHourly = this?.readingsPressure?.alarm_time || [];
      const dataAlarmWeekly = this?.readingsPressure?.alarm_weekly || [];
      const dataPressureDaily = this?.readingsPressure?.daily || [];
      const dataPressureHourly = this?.readingsPressure?.time || [];
      const dataPressureWeekly = this?.readingsPressure?.weekly || [];

      this.amountDataPressure = 1;

      const legendaFinal = [];
      const dateFinal = [];
      const dateFinalDaily = [];
      const dateFinalWeekly = [];

      if (dataPressureHourly.length > 0) {
        const _data_hourly = new Set(dataAlarmHourly);
        for (const hourly of dataPressureHourly) {
          if (dataAlarmHourly && dataAlarmHourly.length > 0) {
            if (_data_hourly.has(hourly[0])) {
              dateFinal.push({
                x: hourly[0], y: hourly[1], marker: {
                  fillColor: 'red'
                }
              });
            } else {
              dateFinal.push({ x: hourly[0], y: parseFloat(hourly[1].toFixed(2)) });
            }
          } else {
            dateFinal.push({ x: hourly[0], y: parseFloat(hourly[1].toFixed(2)) });
          }
          legendaFinal.push(moment(hourly[0]).format('DD/MM/YYYY HH:mm:SS'));
        }
      }
      this.testLoad = false;
      this.chartOptions.pressure.hourly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          zoomType: 'x'
        },
        yAxis: {
          title: {
            text: 'Pressão (m.c.a.)'
          },
          plotLines: [{
            value: this.alarmPressure.lower_limit,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: 'Valor mínimo'
            },
            zIndex: 10
          }, {
            value: this.alarmPressure.upper_limit,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              align: 'right',
              text: 'Valor máximo',
              x: -40
            },
            zIndex: 10
          }]
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Pressão'
        },
        tooltip: {
          pointFormat: 'Pressão: <b>{point.y} m.c.a.</b>'
        },
        xAxis: {
          categories: legendaFinal
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              valueDecimals: 2
            },
            turboThreshold: 10000
          }
        },
        series: [{
          marker: {
            enabled: true
          },
          name: 'Pressão (MCA)',
          data: dateFinal,
          type: 'spline',
          tooltip: {
            valueDecimals: 2
          }
        }]
      }

      if (dataPressureDaily.length > 0) {
        for (let index = 0; index < dataPressureDaily.length; index++) {
          let daily = dataPressureDaily[index];

          if (dataAlarmDaily && dataAlarmDaily.length > 0) {
            if (dataAlarmDaily.find(alarm => alarm == daily[0])) {
              dateFinalDaily.push({
                x: daily[0],
                low: daily[1],
                q1: daily[2],
                median: daily[3],
                q3: daily[4],
                high: daily[5],
                color: "red"
              });
            } else {
              dateFinalDaily.push({
                x: daily[0],
                low: daily[1],
                q1: daily[2],
                median: daily[3],
                q3: daily[4],
                high: daily[5],
                color: ""
              });
            }
          } else {
            dateFinalDaily.push({
              x: daily[0],
              low: daily[1],
              q1: daily[2],
              median: daily[3],
              q3: daily[4],
              high: daily[5],
              color: ""
            });
          }
        }
      }

      this.chartOptions.pressure.daily = {
        time: {
          timezoneOffset: this.local * 60,
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Pressão'
        },
        yAxis: {
          title: {
            text: 'Pressão (m.c.a.)'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return '<b>Pressão</b>' + '</br></br>'
              + '</b> Limite superior:' + '&nbsp;' + `<b>${this.y}</b>` + '</br>'
              + '</b> Terceiro quartil:' + '&nbsp;' + `<b>${this.points[0].point.options.q3}</b>` + '</br>'
              + '</b> Mediana:' + '&nbsp;' + `<b>${this.points[0].point.options.median}</b>` + '</br>'
              + '</b> Primeiro quartil:' + '&nbsp;' + `<b>${this.points[0].point.options.q1}</b>` + '</br>'
              + '</b> Limite inferior:' + '&nbsp;' + `<b>${this.points[0].point.options.low}</b>` + '</br></br>'
              + `<b>${Highcharts.dateFormat('%A, %d %b, %Y', this.x)}</b>`;
          }
        },
        series: [{
          type: 'boxplot',
          name: 'Pressão',
          data: dateFinalDaily
        }]
      }

      if (dataPressureWeekly.length > 0) {
        for (let index = 0; index < dataPressureWeekly.length; index++) {
          let weekly = dataPressureWeekly[index];

          if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
            if (dataAlarmWeekly.find(alarm => alarm == weekly[0])) {
              dateFinalWeekly.push({
                x: weekly[0],
                low: weekly[1],
                q1: weekly[2],
                median: weekly[3],
                q3: weekly[4],
                high: weekly[5],
                color: "red"
              });
            } else {
              dateFinalWeekly.push({
                x: weekly[0],
                low: weekly[1],
                q1: weekly[2],
                median: weekly[3],
                q3: weekly[4],
                high: weekly[5],
                color: ""
              });
            }
          } else {
            dateFinalWeekly.push({
              x: weekly[0],
              low: weekly[1],
              q1: weekly[2],
              median: weekly[3],
              q3: weekly[4],
              high: weekly[5],
              color: ""
            });
          }
        }
      }

      this.chartOptions.pressure.weekly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Pressão'
        },
        yAxis: {
          title: {
            text: 'Pressão (m.c.a.)'
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>Pressão</b>' + '</br></br>'
              + '</b> Limite superior:' + '&nbsp;' + `<b>${this.y}</b>` + '</br>'
              + '</b> Terceiro quartil:' + '&nbsp;' + `<b>${this.points[0].point.options.q3}</b>` + '</br>'
              + '</b> Mediana:' + '&nbsp;' + `<b>${this.points[0].point.options.median}</b>` + '</br>'
              + '</b> Primeiro quartil:' + '&nbsp;' + `<b>${this.points[0].point.options.q1}</b>` + '</br>'
              + '</b> Limite inferior:' + '&nbsp;' + `<b>${this.points[0].point.options.low}</b>` + '</br></br>'
              + `<b>${Highcharts.dateFormat('%A, %d %b, %Y', this.x)}</b>`;
          }
        },
        series: [{
          type: 'boxplot',
          name: 'Pressão',
          data: dateFinalWeekly
        }]
      }
    },
    redrawNoise: function () {
      var dataNoiseHourly = (this.readingsNoise && this.readingsNoise.hourly) ? this.readingsNoise.hourly : [];
      var dataNoiseDaily = (this.readingsNoise && this.readingsNoise.daily) ? this.readingsNoise.daily : [];
      var dataNoiseWeekly = (this.readingsNoise && this.readingsNoise.weekly) ? this.readingsNoise.weekly : [];
      var dataAlarmHourly = (this.readingsNoise && this.readingsNoise.alarm_hourly) ? this.readingsNoise.alarm_hourly : [];
      var dataAlarmDaily = (this.readingsNoise && this.readingsNoise.alarm_daily) ? this.readingsNoise.alarm_daily : [];
      var dataAlarmWeekly = (this.readingsNoise && this.readingsNoise.alarm_weekly) ? this.readingsNoise.alarm_weekly : [];

      this.amountDataNoise = 1;

      const legendaFinal = [];
      const dateFinal = [];
      const dateFinalDaily = [];
      const dateFinalWeekly = [];

      if (dataNoiseHourly.length > 0) {
        let total = dataNoiseHourly.length;
        for (let index = 0; index < total; index++) {
          let hourly = dataNoiseHourly[index];

          if (dataAlarmHourly && dataAlarmHourly.length > 0) {
            if (dataAlarmHourly.find(alarm => alarm == hourly[0])) {
              dateFinal.push({
                x: hourly[0], y: hourly[1], marker: {
                  fillColor: 'red'
                }
              });
            } else {
              dateFinal.push({ x: hourly[0], y: parseFloat(hourly[1].toFixed(2)) });
            }
          } else {
            dateFinal.push({ x: hourly[0], y: parseFloat(hourly[1].toFixed(2)) });
          }
          legendaFinal.push(moment(hourly[0]).format('DD/MM/YYYY HH:mm:SS'));
        }
      }

      this.chartOptions.noise.hourly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        chart: {
          zoomType: 'x'
        },
        yAxis: {
          title: {
            text: 'Ruído %'
          },
          labels: {
            format: '{value}%'
          }
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Ruído'
        },
        tooltip: {
          pointFormat: 'Ruído: <b>{point.y}%</b>'
        },
        xAxis: {
          categories: legendaFinal
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              valueDecimals: 2
            }
          }
        },
        series: [{
          marker: {
            enabled: true
          },
          name: 'Ruído',
          data: dateFinal,
          type: 'spline',
          tooltip: {
            valueDecimals: 2
          }
        }]
      }

      if (dataNoiseDaily.length > 0) {
        for (let index = 0; index < dataNoiseDaily.length; index++) {
          let daily = dataNoiseDaily[index];

          if (dataAlarmDaily && dataAlarmDaily.length > 0) {
            if (dataAlarmDaily.find(alarm => alarm == daily[0])) {
              dateFinalDaily.push({
                x: daily[0],
                open: daily[1],
                high: daily[2],
                low: daily[3],
                close: daily[4],
                color: "red"
              });
            } else {
              dateFinalDaily.push({
                x: daily[0],
                open: daily[1],
                high: daily[2],
                low: daily[3],
                close: daily[4],
                color: ""
              });
            }
          } else {
            dateFinalDaily.push({
              x: daily[0],
              open: daily[1],
              high: daily[2],
              low: daily[3],
              close: daily[4],
              color: ""
            });
          }
        }
      }

      this.chartOptions.noise.daily = {
        time: {
          timezoneOffset: this.local * 60,
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Ruído'
        },
        yAxis: {
          title: {
            text: 'Ruído %'
          },
          labels: {
            format: '{value}%'
          }
        },
        series: [{
          type: 'candlestick',
          name: 'Ruído',
          data: dateFinalDaily
        }]
      }

      if (dataNoiseWeekly.length > 0) {
        for (let index = 0; index < dataNoiseWeekly.length; index++) {
          let weekly = dataNoiseWeekly[index];

          if (dataAlarmWeekly && dataAlarmWeekly.length > 0) {
            if (dataAlarmWeekly.find(alarm => alarm == weekly[0])) {
              dateFinalWeekly.push({
                x: weekly[0],
                open: weekly[1],
                high: weekly[2],
                low: weekly[3],
                close: weekly[4],
                color: "red"
              });
            } else {
              dateFinalWeekly.push({
                x: weekly[0],
                open: weekly[1],
                high: weekly[2],
                low: weekly[3],
                close: weekly[4],
                color: ""
              });
            }
          } else {
            dateFinalWeekly.push({
              x: weekly[0],
              open: weekly[1],
              high: weekly[2],
              low: weekly[3],
              close: weekly[4],
              color: ""
            });
          }
        }
      }

      this.chartOptions.noise.weekly = {
        time: {
          timezoneOffset: this.local * 60,
        },
        rangeSelector: {
          enabled: false
        },
        title: {
          text: 'Ruído'
        },
        yAxis: {
          title: {
            text: 'Ruído %'
          },
          labels: {
            format: '{value}%'
          }
        },
        series: [{
          type: 'candlestick',
          name: 'Ruído',
          data: dateFinalWeekly
        }]
      }
    },

    async getData() {
      let adjTz = moment()
        .tz(`${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
        .format('Z')
        .toString()
        .split(':')[0];
      
      adjTz = (Math.abs(parseInt(adjTz))).toString()
      adjTz = adjTz.length == 1 ? ` 0${adjTz}:00` : ` ${adjTz}:00`

      if (this.listDevices.length) {
        for (const { device_id, type } of this.listDevices) {
          const { jsonFilter, jsonFilter2 } = this.mountReadingsChartsPayload(device_id, type, adjTz);

          try {
            const response = await readingsCharts(jsonFilter)
              .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                  return res;
                }
              })

            let responseVolume = {}
            if (type == 'flow') {
              responseVolume = await readingsCharts(jsonFilter2)
                .then((res) => {
                  if (res.status >= 200 && res.status <= 299) {
                    return res;
                  }
                })
            }

            if (response && response.message) {
              alert('Dispositivo não encontrado')
            }

            switch (type) {
              case 'flow':
                this.readingsFlow = response.data;
                this.readingsVolume = responseVolume.data;
                break;
              case 'pressure':
                this.readingsPressure = response.data
                break;
              case 'noise':
                this.readingsNoise = response.data
                break;
              default:
                break;
            }
          } catch (err) {
            this.showErrorServer('Erro ao carregar a(s) leitura(s) do(s) dispositivo(s)!');
            this.testLoad = false;
          }
        }
      }
    },
    mountReadingsChartsPayload(device_id, type, adjTz) {
      const jsonFilter = {
        device_id,
        from: this.dataInicial + adjTz,
        to: this.dataFinal,
        time_format: "timestamp"
      };
      const jsonFilter2 = {};

      switch (type) {
        case 'flow':
          jsonFilter.hourly_type = "column";
          jsonFilter.daily_type = "boxplot";
          jsonFilter.weekly_type = "boxplot";
          jsonFilter.cummulated = true;
          jsonFilter.delta = true;
          jsonFilter.interpolated = false;

          jsonFilter2.device_id = device_id;
          jsonFilter2.from = this.dataInicial + adjTz;
          jsonFilter2.to = this.dataFinal;
          jsonFilter2.time_format = "timestamp";
          jsonFilter2.hourly_type = "column";
          jsonFilter2.daily_type = "boxplot";
          jsonFilter2.weekly_type = "boxplot";
          jsonFilter2.cummulated = true;
          jsonFilter2.delta = false;
          jsonFilter2.interpolated = false;
          break;
        case 'pressure':
          jsonFilter.hourly_type = "line";
          jsonFilter.daily_type = "boxplot";
          jsonFilter.weekly_type = "boxplot";
          jsonFilter.cummulated = false;
          jsonFilter.interpolated = false;
          jsonFilter.delta = false;
          break;
        case 'noise':
          jsonFilter.hourly_type = "line";
          jsonFilter.daily_type = "candlestick";
          jsonFilter.weekly_type = "candlestick";
          jsonFilter.cummulated = false;
          jsonFilter.delta = false;
          jsonFilter.interpolated = false;
          break;
        default:
          break;
      }

      return { jsonFilter, jsonFilter2 };
    },
    createdList() {
      this.listDevices = [];
      this.listDevices.push(this.selectedDevice)

      const { type, alarm_boundaries } = this.selectedDevice;

      if (type === 'flow') {
        this.alarmFlow = alarm_boundaries
          ? alarm_boundaries
          : { lower_limit: 0, upper_limit: 0 };

      } else if (type === 'pressure') {
        this.alarmPressure = alarm_boundaries
          ? alarm_boundaries
          : { lower_limit: 0, upper_limit: 0 };
      }
    },
    onResize() {
      if (this.$route.path === '/dashboard') {
        const aa = document.getElementById("div1") != null ? document.getElementById("div1").getBoundingClientRect().width : 0;
        if (this.listDevices.length) {
          this.listDevices.map((selectedDevice) => {
            if (selectedDevice.type === "flow") {
              this.chartOptions.flow.hourly = { chart: { width: aa } }
              this.chartOptions.flow.daily = { chart: { width: aa } }
              this.chartOptions.flow.weekly = { chart: { width: aa } }

              this.chartOptions.volume.hourly = { chart: { width: aa } }
              this.chartOptions.volume.daily = { chart: { width: aa } }
              this.chartOptions.volume.weekly = { chart: { width: aa } }
              setTimeout(() => {
                this.redrawFlow();
              }, 100);
            }
            if (selectedDevice.type === "noise") {
              this.chartOptions.noise.hourly = { chart: { width: aa } }
              this.chartOptions.noise.daily = { chart: { width: aa } }
              this.chartOptions.noise.weekly = { chart: { width: aa } }
              setTimeout(() => {
                this.redrawNoise();
              }, 100);
            }
            if (selectedDevice.type === "pressure") {
              this.chartOptions.pressure.hourly = { chart: { width: aa } }
              this.chartOptions.pressure.daily = { chart: { width: aa } }
              this.chartOptions.pressure.weekly = { chart: { width: aa } }
              setTimeout(() => {
                this.redrawPressure();
              }, 100);
            }
          })
        }
      }
    }
  },

  async created() {
    await this.createdList();
    await this.getData();
    this.emitter.on('my-event-controlSidebar', () => {
      this.onResize();
    })
  },
  mounted() {
    this.local = store.local;
    if (store.summer == true) {
      this.local--
    }
  },
  setup() {
    const toast = useToast();
    const showErrorServer = (errorMessage) => {
      toast.add({ severity: 'error', summary: 'Error', detail: errorMessage || " Erro ao acessar o servidor!", life: 2500 });
    }
    return { showErrorServer }
  }
}
</script>
<style>
body {
  background: #F1EEEE;
}

.charts {
  position: relative;
  margin: 0px auto 10px auto;
}

.hc {
  position: relative;
  background-color: #FFF;
  padding-top: 1rem;
  border-radius: 15px;
}

.hidden {
  height: 20px;
  width: 20%;
  position: absolute;
  bottom: 0px;
  background: #fff;
  z-index: 9;
  right: 0px;
  border-radius: 25px;
}

.hidden.smaller {
  height: 12px;
}

.highcharts-data-labels .highcharts-label.highcharts-data-label.highlight text {
  fill: red !important;
  color: red !important;
}
</style>