<template>
<Toast />
  <body>
    <div class="groupCadastros">
    </div>
    <main>
      <div class="card col-sm-7" id="main_box">
        <div class="card-body">
          <p>Ativar Dispositivo</p>
          <div class="row">
          <p style="font-size:1rem">* Campo Obrigatório</p>
          <p style="font-size:1rem">** Para ativar o dispositivo, passar o imã três vezes.</p>
          <div class="col-md-12">
              <div class="form-floating">
                <!-- <InputMask id="floatingInputGrid" v-model="snBox" mask="99999X9999X999999" class="form-control" aria-describedby="addon-wrapping" autoFocus={true} /> -->
                <InputMask id="floatingInputGrid" v-model="snBox" mask="*****************" class="form-control" aria-describedby="addon-wrapping" autoFocus={true} />
                <label for="floatingInputGrid">Serial Box *</label>
              </div>
            </div>
          </div>
          <div class="d-grid gap-2 col-8 mx-auto">
            <button @click="submit" class="btn btn-login" type="button">
              Ativar
            </button>
          </div>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import { store } from "../services/store.js";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import InputMask from 'primevue/inputmask';
import { ativation } from "../services/crud.js";
const $loading = useLoading();
export default {
  components: {
    Toast,
    InputMask
  },
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      snBox: '',
      client_id:''
    };
  },
  methods: {
    submit: async function () {
      
      if (this.snBox.length == 17) {
        const loader = $loading.show({});
        const dataForm = {
          "serial_box": this.snBox,
          "client_id": store.client_id,
          "user_id": store.user_id
        };

          await ativation(dataForm)
          .then((response) => {
            if(response.status >= 200 && response.status <= 299 && response.data.erro != true){
              this.showsuccess()
              this.$router.replace('/listagem/ativacao')
            }else{
              this.showErrorApi(response.data.message)
            }          
          })
          .catch((err) =>
            this.showErrorServer(err.message)
          );
        loader.hide();       
         
      } else {
        this.showError()
      }
      
    },
  },
  async mounted() {
  
  },
  setup(){
    const toast = useToast();
    
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 2500});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 2500});
    }
    const showErrorApi = () => {
      toast.add({severity:'error', summary: 'Erro !', detail:"Ocorreu um erro!", life: 2500});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:" Dispositivo cadastrado com sucesso! ", life: 2500});
    }
    const showWarn = (res) => {
      toast.add({severity:'warn', detail:"Atenção!\n\n "+res+".", life: 2500});
    }
    return {showsuccess,showErrorServer,showError,showWarn,showErrorApi}
  }
};
</script>

<style scoped>
p{
  font-size: 0.7rem;
}
body {
  background-size: cover;
  height: 100vh;
  margin-top: -1rem;
}
.logo_stattus {
  margin: 3rem;
  width: 25%;
  height: 12vh;
}
.img_persona {
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
}
.input-group {
  margin: 1rem 0rem;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card {
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.dropdown-item {
  padding: 0rem 0rem;
}
p {
  font-size: 1.5rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
  padding-right: 0.2rem;
}
.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}
.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) {
  #main_box {
    width: 52%;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem
  }
  .multiselect-caret{
    margin-top: -0.5rem;
  }
  .multiselect-single-label{
    margin-top: 0.3rem;
  }
  .multiselect-clear{
    margin-top: -0.3rem;
  }
}
</style>