<template>
    <div style="display: flex; justify-content: space-evenly; margin-bottom: 1rem">
        <div v-show="iconDevicePressure">
          <input type="radio" id="pressure" value="pressure" v-model="checkedNames">
          <label for="pressure">
            <div v-if="checkedNames.includes('pressure') || typeSensors.length === 1">
              <img src="../assets/pressure_On.png" width="50" height="50" style="cursor: pointer">
            </div> 
            <div v-else>
              <img src="../assets/pressure_Off.png" width="50" height="50" style="cursor: pointer" >
            </div>
          </label>
        </div>
        <div v-show="iconDeviceFlow">
          <input type="radio" id="flow" value="flow" v-model="checkedNames">
          <label for="flow">
            <div v-if="checkedNames.includes('flow') || typeSensors.length === 1">
              <img src="../assets/flow_On.png" width="50" height="50" style="cursor: pointer">
            </div> 
            <div v-else>
              <img src="../assets/flow_Off.png" width="50" height="50" style="cursor: pointer" >
            </div>
          </label>
        </div>
        <div v-show="iconDeviceNoise">
          <input type="radio" id="noise" value="noise" v-model="checkedNames">
          <label for="noise">
            <div v-if="checkedNames.includes('noise') || typeSensors.length === 1">
              <img src="../assets/noise_On.png" width="50" height="50" style="cursor: pointer">
            </div> 
            <div v-else>
              <img src="../assets/noise_Off.png" width="50" height="50" style="cursor: pointer" >
            </div>
          </label>
        </div>
      </div>
</template>


<script>
export default {
    name: "FiltroSensores",
    emits:['FiltraSensor'],
    props: {
      typeSensors: Array,
      verifyType: String,
    },
    data() {
        return {
            checkedNames: [],
            iconDeviceFlow: false,
            iconDevicePressure: false,
            iconDeviceNoise: false,
        }
    },
    methods: {
     checkSensorPress () {   
        this.$emit('FiltraSensor',this.checkedNames)
     },
     resetSensor() {
      this.checkedNames = [];
     }
    },
    watch: {
    checkedNames: function () {
      this.checkSensorPress();
    }, 
    typeSensors: function () {
      this.iconDeviceFlow = this.typeSensors.includes('flow');
      this.iconDevicePressure = this.typeSensors.includes('pressure')
      this.iconDeviceNoise = this.typeSensors.includes('noise')
    },
    verifyType: function (data) {
      if( data != null ) {
        this.checkedNames = data
      }
    }
  },
  created() {
    this.emitter.on('filter-reset', () => {
      this.resetSensor();
    })
  }
}
</script>

<style scoped>
input[type=radio] {
    display:none;
}

input[type=radio] + label {
    display:inline-block;
    padding: 0 0 0 0px;
    height: 50px;
    width: 50px;;
    background-size: 100%;
}

input[type=radio]:checked + label {
    height: 50px;
    width: 50px;
    display:inline-block;
    background-size: 100%;
}
</style>