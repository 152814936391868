<template>
  <Toast />
  <div style="display: flex; justify-content: center">
    <div class="col-md-7">
      <div class="tableBox">
        <div class="col-sm" id="main_box">
          <div class="card-body">
            <h5>Ativar Dispositivo</h5>
            <div class="row" style="justify-content: space-around">
              <p style="font-size: 1rem">* Campo Obrigatório</p>
              <p style="font-size: 1rem">
                ** Para ativar o dispositivo, passar o imã três vezes.
              </p>
              <div class="col-md-8">
                <div class="form-floating">
                  <InputMask
                    id="floatingInputGrid"
                    v-model="snBox"
                    mask="*****************"
                    class="form-control"
                    aria-describedby="addon-wrapping"
                    autoFocus="{true}"
                  />
                  <label for="floatingInputGrid">Serial Box *</label>
                </div>
              </div>
              <button @click="activeDevice" class="btn btn-login" type="button">
                Ativar
              </button>
            </div>
          </div>
        </div>
        <div class="head-btn" style="margin-left: 15px">
          <h5>Listagem de dispositivos ativados</h5>
        </div>
        <DataTable
          v-model:filters="filters"
          filterDisplay="menu"
          :value="products"
          :paginator="true"
          :rows="50"
          class="paginator-custom"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          v-model:selection="selectedProduct1"
          selectionMode="single"
          dataKey="id"
          sortField="created_at"
          :sortOrder="1"
          currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
          scrollable
        >
          <template #empty>Sem Dados Disponíveis</template>
          <Column field="created_at" header="Data de Ativação" :sortable="true">
            <template #body="{ data }"
              >{{ new Date(data.created_at).toLocaleString("pt-br") }}
            </template>
          </Column>
          <Column field="serial_number" header="Serial Box"></Column>
          <Column field="type" header="Tipo">
            <template #body="{ data }">{{
              data.type == "flow"
                ? "Vazão"
                : data.type == "pressure"
                ? "Pressão"
                : "Ruído"
            }}</template>
          </Column>
          <Column header="Histórico" bodyStyle="text-align: center; overflow: visible">
                <template #body="{data}">
                    <Button type="button" class="btn shortcut" icon="pi pi-book" @click="getSerialBox(data.serial_number)" ></Button>
                </template>
          </Column>
          <Column
            field="status"
            header="Status de Comunicação"
            :showAddButton="false"
            :showFilterOperator="false"
            :showFilterMatchModes="false"
          >
            <template #body="{ data }">
              <div
                :class="data.communication == 'ativado' ? 'ativado' : data.communication == 'aguardando' ? 'aguardando' : 'erro'"
              >
                <span>
                  {{ data.communication == "ativado" ? "Ativado" : data.communication == "aguardando" ? "Aguardando..." : "Erro ao ativar" }}
                </span>
              </div>
            </template>
            <template #filter="{ filterModel }" showFilterOperator="false">
              <Dropdown
                v-model="filterModel.value"
                :options="typeStatus"
                placeholder="Pesquisa Status"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                icon="pi pi-times"
                @click="filterCallback()"
                class="p-button-secondary"
              ></Button>
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                icon="pi pi-check"
                @click="filterCallback()"
                class="p-button-success"
              ></Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-md-4 tableBox" style="background: white">
      <h5 for="floatingInputGrid" class="card-body">
        Histórico de dispositivo
      </h5>
      <div class="row" style="justify-content: space-around">
        <div class="col-md-7">
          <div class="form-floating">
            <InputMask
              id="floatingInputGrid"
              v-model="snBoxSearch"
              mask="*****************"
              class="form-control"
              aria-describedby="addon-wrapping"
              autoFocus="{true}"
            />
            <label for="floatingInputGrid">Serial Box *</label>
          </div>
        </div>
        <button class="btn btn-login" type="button" @click="history_device">
          Pesquisar
        </button>
      </div>
      <div class="card-body">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td v-if="historyData.serial_number">
                <span style="font-weight: 500">Serial Box:</span>
                {{ historyData.serial_number }}
              </td>
            </tr>
            <tr>
              <td v-if="historyData.created_at">
                <span style="font-weight: 500">Data de criação:</span>
                {{ new Date(historyData.created_at).toLocaleString("pt-br") }}
              </td>
            </tr>
            <tr>
              <td v-if="historyData.type">
                <span style="font-weight: 500">Tipo:</span>
                {{
                  historyData.type == "flow"
                    ? "Vazão"
                    : historyData.type == "pressure"
                    ? "Pressão"
                    : "Vazão"
                }}
              </td>
            </tr>
            <tr>
              <td v-if="historyData.version">
                <span style="font-weight: 500">Versão:</span>
                {{ historyData.version }}
              </td>
            </tr>
            <tr v-if="historyCommunication.length > 0">
              <td v-if="historyData.communication">
                <span style="font-weight: 500">Comunicação:</span>
                <div v-for="item in historyCommunication" :key="item">
                  {{ new Date(item).toLocaleString("pt-br") }}
                </div>
              </td>
              <td v-else>
                <span style="font-weight: 500">Comunicação:</span>
                <span> Aguardando a primeira comunicação...</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import { useLoading } from "vue-loading-overlay";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import {
  listClients,
  upDateDevice,
  filterDevice,
  ativation,
} from "../services/crud.js";
import { store } from "../services/store.js";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import moment from "moment";

moment.locale("pt-BR");

const $loading = useLoading();

export default {
  components: {
    Column,
    DataTable,
    Toast,
    InputMask,
    Dropdown,
    Button,
  },
  data() {
    return {
      now: moment(),
      snBox: "",
      snBoxSearch: "",
      temp: null,
      isSuper: store.type_user == 1 ? true : false,
      endereco: "",
      value: "",
      listaDeClientes: [],
      dataForm: {},
      tiposDispositivos: [
        { label: "Vazão", value: "flow" },
        { label: "Pressão", value: "pressure" },
        { label: "Ruído", value: "noise" },
      ],

      placeholder: "Cliente",
      arrClients: [],
      historyData: {},
      historyCommunication: {},
      typeStatus: ["Ativado", "Aguardando", "Erro"],
      filters: {
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      },
    };
  },
  methods: {
    saveEdit: async function () {
      const dataUpdate = {
        address: this.dataForm.address,
        client_id: this.clients,
        id: this.id,
        status: this.dataForm.status,
        type: this.type,
        serial_number: this.serial_number,
        tags: this.tags,
        setup: JSON.parse(this.setup),
      };
      if (
        !this.type ||
        !this.type.trim() ||
        !this.id ||
        !this.id.trim() ||
        !this.serial_number ||
        !this.serial_number.trim() ||
        !this.clients
      ) {
        this.showError();
      } else {
        await upDateDevice(dataUpdate)
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              this.showsuccess();
            }
          })
          .catch((err) => this.showErrorServer(err));
      }
    },
    activeDevice: async function () {
      if (this.snBox.length == 17) {
        const loader = $loading.show({});
        const dataForm = {
          serial_box: this.snBox,
          client_id: store.client_id,
          user_id: store.user_id,
        };

        await ativation(dataForm)
          .then((response) => {
            if (
              response.status >= 200 &&
              response.status <= 299 &&
              response.data.erro != true
            ) {
              this.showsuccess();
            } else {
              this.showErrorApi(response.data.message);
            }
          })
          .catch((err) => this.showErrorServer(err.message));
        loader.hide();
      } else {
        this.showError();
      }
    },
    history_device: async function () {
      let aux = [];
      if (this.snBoxSearch.length == 17) {
        const loader = $loading.show({});
        const data = {
          serial_number: this.snBoxSearch,
        };
        await filterDevice(data)
          .then((response) => {
            Object.values(response.data).forEach((res) => {
              this.historyData = res;
            });
            if (this.historyData.communication) {
              Object.keys(this.historyData.communication).forEach((res) => {
                if(res != 'error' && res != 'message') {
                  if (res.length < 13) {
                    res = res * 1000;
                  }
                  let out = parseInt(res);
                  aux.push(out);
                }
              });
             
              this.historyCommunication = aux;              
            }
            this.snBoxSearch = "";
          })
          .catch((error) => {
            this.showErrorServer(error);
            this.snBoxSearch = "";
          });
        loader.hide();
      } else {
        this.showError();
      }
    },
    getSerialBox(data) {
      this.snBoxSearch = data;
      this.history_device();
    },
  },
  watch: {
    selectedProduct1: function (val) {
      const dataF = {
        address: val.address,
        created_at: val.created_at,
        id: val.id,
        status: val.status,
        type: val.type,
        serial_number: val.serial_number,
        tags: val.tags,
        setup: val.setup,
        clients: val.clients,
      };
      this.dataForm = dataF;
    },
  },
  setup() {
    let listClientsArr = [];
    let listaClientes = [];
    const products = ref();
    const type = ref();
    const id = ref();
    const serial_number = ref();
    const tags = ref();
    const created_at = ref();
    const client_id = ref();
    const clients = ref();
    const setup = ref();
    const status = ref();
    const communication = ref();
    const communication_event = ref();
    let clientsLabel = ref();
    const selClient = ref();
    const updated_at = ref();
    const filterDate = ref();

    const getDevices = async () => {
      let aux = [];
      let arrAux = [];
      let arrData = [];
      let auxDevice = {};
      let temp = [];

      if (store.type_user != 1) {
        auxDevice = {
          client_id: store.client_id,
          install_point_id: null,
        };
      }

      await listClients()
        .then((response) => {
          listClientsArr = [];
          clients.value = response.data;
          for (let i = 0; i < clients.value.length; i++) {
            listClientsArr.push({
              label: clients.value[i].trading_name,
              value: clients.value[i].id,
            });
          }
          listaClientes = listClientsArr;
        })
        .catch(() => console.log("erro"));

      await filterDevice(auxDevice)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            aux = listClientsArr.filter((res) => {
              return res.value == response.data[i].client_id;
            });
            temp[i] =
              response.data[i].communication != null
                ? Object.keys(response.data[i].communication)
                : "";
            arrData = {
              brand: response.data[i].brand,
              client_id: response.data[i].client_id,
              created_at: response.data[i].created_at,
              id: response.data[i].id,
              install_point_id: response.data[i].install_point_id,
              serial_number: response.data[i].serial_number,
              setup: response.data[i].setup,
              tags: response.data[i].tags,
              type: response.data[i].type,
              updated_at: response.data[i].updated_at,
              user_id: response.data[i].user_id,
              clients: aux.length > 0 ? aux[0].value : "Não Informado",
              clientsLabel: aux.length > 0 ? aux[0].label : "Não Informado",
              status: response.data[i].communication == null ? "aguardando" : response.data[i].communication.error ? "erro" : "ativado",
              communication: response.data[i].communication == null ? "aguardando" : response.data[i].communication.error ? "erro" : "ativado",
              filterDate: temp[i][1]
                ? new Date(parseInt(temp[i][1])).toLocaleString("pt-br")
                : new Date(parseInt(temp[i][0])).toLocaleString("pt-br"),
            };
            arrAux.push(arrData);
          }
          products.value = arrAux;
        })
        .catch(() => console.log("erro"));
    };

    const selectedProduct1 = ref();
    let listSensores = ref([]);

    onMounted(async () => {
      const loader = $loading.show({});
      getDevices();
      loader.hide();
      setInterval(() => {
        getDevices();
      }, 30000);
    });
    const toast = useToast();
    const showError = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Campos Obrigatórios não foram preenchidos!",
        life: 4000,
      });
    };
    const showErrorServer = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: " Erro ao acessar o servidor!",
        life: 4000,
      });
    };
    const showErrorApi = () => {
      toast.add({
        severity: "error",
        detail: 'Ocorreu um erro!',
        life: 4000,
      });
    };
    const showsuccess = () => {
      toast.add({
        severity: "success",
        detail: " Dados de dispositivo alterados com sucesso! ",
        life: 4000,
      });
    };
    return {
      showError,
      showErrorServer,
      showsuccess,
      showErrorApi,
      client_id,
      products,
      listSensores,
      selectedProduct1,
      clients,
      listClients,
      id,
      type,
      serial_number,
      tags,
      created_at,
      setup,
      clientsLabel,
      listaClientes,
      selClient,
      status,
      updated_at,
      communication,
      communication_event,
      filterDate,
    };
  },
};
</script>

<style scoped>
h2 {
  margin: 1rem;
}
.btn-outline-success {
  border-color: #6eff94;
  color: black;
  background-color: white;
}
.ativado {
  background-color: #6eff94;
  color: #000;
  text-align: center;
  flex: auto;
}
.aguardando {
  background-color: khaki;
  color: #000;
  text-align: center;
  flex: auto;
}
.erro {
  background-color: #DC3545;
  color: #000;
  text-align: center;
  flex: auto;
}
.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}
.btn-check:checked + .btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}
.btn-login {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  place-self: center;
  width: 8rem;
  font-weight: 450;
}
.form-floating {
  margin: 1rem 0rem;
}

.form-floating > .form-control,
.form-floating > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
  margin: 0rem 0.2rem;
}
.input-group > .form-control,
.input-group > .form-select {
  background-color: #e8f0fe;
  color: black;
  line-height: 3rem;
}
.input-group {
  margin: 1rem 0rem;
}
.multiselect {
  height: 3.63rem;
  margin: 1rem 0rem;
  background: #e8f0fe;
}
input::placeholder {
  color: black;
}
.input-group-text {
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.groupCadastros {
  text-align-last: center;
}
.dropdown-item {
  padding: 0rem 0rem;
}
.tableBox {
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 95%;
}
.btn-router {
  line-height: 2rem;
  width: 100%;
}
.groupCadastros {
  text-align-last: center;
  padding-top: 3rem;
}
.shortcut:hover{
  background-color: #6eff94;
  border-color: #6eff94;
  color: #000
}
.shortcut{
  background-color: #6eff94;
  border-color: #6eff94;
  color: #000
}
@media screen and (max-width: 500px) {
  .btn-group {
    display: block;
  }
  .head-btn {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  .head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
::v-deep(.paginator-custom) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6eff94;
    border-color: #e3f2fd;
    color: #495057;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #6eff94;
  }
}
::v-deep(.multiselect-custom) {
  .multiselect-placeholder {
    color: black;
    margin-top: 0.3rem;
  }
  .multiselect-caret {
    margin-top: -0.5rem;
  }
  .multiselect-single-label {
    margin-top: 0.3rem;
  }
  .multiselect-clear {
    margin-top: -0.3rem;
  }
}
</style>