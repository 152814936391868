import { reactive } from 'vue'

export const store = reactive({
  client_id:'',
  token: '',
  user_id: '',
  name: '',
  trading_name:'',
  id: '',
  name_client: '',
  type_user: '',
  local: '',
  local_country: '',
  summer: false,
  last_search:'',
  last_search_name:'',
  roles: [],
  demo: null
})