export const StyleMap = [
    {
        featureType: "poi.attraction",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.government",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.medical",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.school",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.sports_complex",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.station",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
]

export const StyleMapFair = [
    {
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
    },
    {
        featureType: "poi.attraction",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.government",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.medical",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.school",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.sports_complex",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.station",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
]