<template>
  <div style="text-align: center;">
    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" class="btn-check" name="btnradio" id="btnR1" autocomplete="off" value="1" v-model="choose"
        checked />
      <label class="btn btn-outline-success" for="btnR1">Alarme</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnR2" autocomplete="off" value="2" v-model="choose" />
      <label class="btn btn-outline-success" for="btnR2">Controle</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnR3" autocomplete="off" value="3" v-model="choose" />
      <label class="btn btn-outline-success" for="btnR3">Comunicações</label>
    </div>
  </div>

  <div v-if="loaderLog" class="card" style="border-radius:15px; margin-top:10px">
    <div class="grid formgrid">
      <div class="field col-12 md:col-6 md:pr-6 pr-0">
        <div class="custom-skeleton p-4">
          <Skeleton width="100%" height="40px"></Skeleton>
          <div class="flex justify-content-center mt-3">
            <Skeleton width="100%" height="80px"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="col-sm-12" style="text-align:start;">
    <div class="items">
      <div v-if="this.choose == 1">
        <div class="my-scroll-container" style="width:auto">
          <table class="table table-borderless">
            <tbody v-for="itemAlarm in alarms" :key="itemAlarm">
              <tr>
                <td><span style="font-weight:500;">Data:</span> {{ new Date(itemAlarm.timestamp).toLocaleString("pt-br") }}</td>
                <td><span style="font-weight:500;">Tipo de Alarme:</span> {{ itemAlarm.name.pt }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="this.choose == 2">
        <div class="my-scroll-container" style="width:auto">
          <table class="table table-borderless">
            <tbody v-for="items in metrics" :key="items">
              <tr>
                <td><span style="font-weight:500;">Data:</span> {{ new Date(items.timestamp).toLocaleString("pt-br") }}</td>
                <td><span style="font-weight:500;">Tipo:</span> {{ items.name.pt }}</td>
                <td><span style="font-weight:500;">Valor:</span> {{ items.info.single_value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="this.choose == 3">
        <div class="my-scroll-container" style="width:auto">
          <table class="table table-borderless">
            <tbody v-for="items in readings" :key="items">
              <tr>
                <td><span style="font-weight:500;">Data:</span> {{ new Date(items.timestamp).toLocaleString("pt-br") }}</td>
                <td><span style="font-weight:500;">Comunicação:</span> OK</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { listEvents } from "../services/dashboard.js";
import { useToast } from "primevue/usetoast";
import Skeleton from 'primevue/skeleton';


export default {
  name: "LogTel",
  components: {
    Skeleton
  },
  props: {
    selectedDevice: Object,
  },
  data() {
    return {
      choose: 1,
      metrics: [],
      alarms: [],
      readings: [],
      loaderLog: true,
    }
  },
  async mounted() {
    this.loaderLog = true;
    if (this.selectedDevice) {
      const data = {
        "device_id": this.selectedDevice.device_id
      };
      await listEvents(data)
        .then((response) => {
          if (!response.data.erro) {
            this.loaderLog = false;
            response.data.data.map(event => {
              if (event.info_type == 'metrics') {
                this.metrics.push(event);
              }

              if (event.info_type == 'alarms') {
                this.alarms.push(event);
              }

              if (event.info_type == 'READING' || event.info_type == 'reading') {
                this.readings.push(event);
              }
            })
          }
        })
        .catch((err) => {
          this.showErrorFail(err)
          this.loaderLog = false;
        });
    }
    this.loaderLog = false;
  },
  setup() {
    const toast = useToast();
    const showErrorFail = (data) => {
      toast.add({
        severity: "error",
        summary: data,
        life: 4000,
      });
    };
    return { showErrorFail };
  },
}
</script>
<style>
.btn-outline-success {
  border-color: #6eff94;
  color: black;
}

.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}

.btn-check:checked+.btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}

.items {
  margin-top: 20px;
  margin-left: 30px
}

.my-scroll-container {
  display: block;
  width: 350px;
  height: 190px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
</style>