<template>
  <Dialog
    v-model:visible="displayBasic"
    :style="{ width: '75vw' }"
    :closable="false"
  >
    <template #header>
      <h3 class="col-sm-12">
        <b>
          Detalhamento do Dispositivo Nº
        </b>
        {{ this.selectedCountries.install_point_id }}
      </h3>
    </template>

    <div style="display: flex; justify-content: space-evenly">
      <div class="col-sm-6">
        <p v-if="!demo">
          <b>Endereço: </b> {{ this.selectedCountries.address }},
          {{ this.selectedCountries.number }} ,
          {{ this.selectedCountries.city }} -
          {{ this.selectedCountries.state }}
        </p>
        <p><b> Código de Instalação: </b> {{ this.selectedCountries.name }}</p>
        <p>
          <b> Data de Comissionamento: </b>
          {{
            new Date(this.selectedCountries.created_at).toLocaleString("pt-br")
          }}
        </p>
        <p>
          <b>ID do Dispositivo: </b>{{ selectedCountries.serial_number }}
        </p>       
        <p v-if="selectedCountries.obs && selectedCountries.obs.length > 0">
          <b>Obs: </b>{{ selectedCountries.obs }}
        </p>  
        <div style="margin-top: 2rem">
          <b> Fotos da Instalação : </b>
          <div>
              <Image v-if="imagem_1" :src="url+imagem_1" alt="Image" width="200" preview/>
              <Image v-if="imagem_2" :src="url+imagem_2" alt="Image" width="200" preview style="margin-left:30px; border-radius:15px"/>
          </div>
        </div>
        <p></p>
        

      </div>
      <div class="col-sm-6" style="border-left: solid lightgrey;">
        <div class="infoDevices" >
          <div class="col-sm-10">
            <div
              style="display: flex; flex-direction: column"
              v-if="this.selectedCountries.name"
            >
              <div style="align-self: center">
                <p><b class="titleComponent">Sensor de {{ selectedCountries.type == 'flow' ? 'Vazão' : selectedCountries.type == 'noise' ? 'Ruído' : 'Pressão' }}</b></p>
              </div>
              <div style="display: flex; justify-content: center; flex-direction: row">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 1rem;
                  "
                >
                  <img
                    v-if="selectedCountries.type == 'flow'"
                    style="margin: 1rem"
                    src="../assets/flowOff.png"
                    width="125"
                    height="125"
                  />
                  <img
                    v-else-if="selectedCountries.type == 'pressure'"
                    style="margin: 1rem"
                    src="../assets/pressureOff.png"
                    width="125"
                    height="125"
                  />
                  <img
                    v-else
                    style="margin: 1rem"
                    src="../assets/noiseOff.png"
                    width="125"
                    height="125"
                  />
                   <p style="margin-bottom: 0.5rem; align-self: flex-start">
                    <b> Redefinir Alarme :</b>  <input type="checkbox" v-model="checkboxChecked" />
                  </p>
                </div>
                <div class="sliders">
                  <Slider
                    orientation="vertical"
                    style="margin: 0rem 1rem"
                    v-model="selectedCountries.clickedDevice.slider"
                    v-bind="selectedCountries.type == 'flow'
                      ? slider_flow : selectedCountries.type == 'pressure'
                      ? slider_pressure : slider_noise"
                    class="slider-vertical slider-base"
                    
                  />
                  <div style="align-self: center">
                    <p style="margin-bottom: 0.5rem; width: 6rem">
                      <label style="width: 1.8rem">
                        {{ this.selectedCountries.clickedDevice.slider[1] }}</label
                      >
                      <label>{{ selectedCountries.type == 'flow' ? 'm³/h' : selectedCountries.type == 'pressure' ? 'mca' : '%' }}</label>
                    </p>
                    <p>
                      <label style="width: 1.8rem">
                        {{ this.selectedCountries.clickedDevice.slider[0] }}</label
                      >
                      <label>{{ selectedCountries.type == 'flow' ? 'm³/h' : selectedCountries.type == 'pressure' ? 'mca' : '%' }}</label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-10 spaceTag">
          <b class="titleComponent" style="display: flex; justify-content: center;margin-bottom: 1rem"> Tags Associadas</b>
          <div class="col-sm-12">
            <Chip
              v-for="label in newTagsUp"
              :key="label.name"
              :label="label.name"
              :class="label.color"
              :style="
                label.color != 'yellow' &&
                label.color != 'aqua' &&
                label.color != 'pink'
                  ? `margin:0.5rem; color: white;background-color: ${label.color};`
                  : `margin:0.5rem; color: black;background-color: ${label.color}`
              "
            />
          </div>
          <div style="width: 100%">
            <Button
              class="btn"
              id="btn-tag"
              :label="'+ Associar tag'"
              @click="toggle"
              aria:haspopup="true"
              aria-controls="overlay_panel"
            />
          </div>
        </div>
        
      </div>
    </div>    
    <template #footer>
      <button @click="closeBasic" class="btn btn-danger">Cancelar</button>
      <button @click="saveBoundaries()" class="btn btn-filtrar">Salvar</button>
    </template>
    <OverlayPanel
          ref="op"
          appendTo="body"
          :showCloseIcon="true"
          id="overlay_panel"
          style="width: 450px"
          :breakpoints="{ '960px': '75vw' }"
        >
          <DataTable
            :value="listTags"
            v-model:selection="selectedProduct"
            dataKey="id_name"
            responsiveLayout="scroll"
          >
            <Column
              selectionMode="multiple"
              headerStyle="width: 3em"
              :row-select="newTags(selectedProduct)"
            >
            </Column>
            <Column header="Tag">
              <template #body="slotProps">
                <Chip
                  :key="slotProps.data.label"
                  :label="slotProps.data.name"
                  :class="slotProps.data.color"
                  :style="
                    slotProps.data.color != 'yellow' &&
                    slotProps.data.color != 'aqua' &&
                    slotProps.data.color != 'pink'
                      ? `color: white;background-color: ${slotProps.data.color};`
                      : `color: black;background-color: ${slotProps.data.color}`
                  "
                />
              </template>
            </Column>
          </DataTable>
    </OverlayPanel>
  </Dialog>
</template>


<script>
import Chip from "primevue/chip";
import OverlayPanel from "primevue/overlaypanel";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import Slider from "@vueform/slider";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { defineComponent, ref } from "vue";
import Image from 'primevue/image';
import { URL_IMG } from "../services/config";

import { 
  updateAlarmes, 
  updateTag,
  commissioningDetails,
  } from "../services/dashboard.js";

export default defineComponent({
  name: "CompDetalhamento",
  components: {
    Chip,
    OverlayPanel,
    DataTable,
    Button,
    Column,
    Slider,
    Dialog,
    Image
  },
  props: {
    show: Boolean,
    selectedDevice: Object,
    arrTags: Array
  },
  data() {
    return {
        demo: JSON.parse(localStorage.getItem("userData")).demo,
        url: URL_IMG,
        imagem_1: "",
        imagem_2: "",
        checkboxChecked: false,
        displayBasic: false,
        listTags: [],
        newTagsUp:{},
        selectedCountries: {
          name: "",
          clickedDevice: { slider: null },
      },
      slider_flow: {
        orientation: "vertical",
        direction: "rtl",
        tooltips: false,
        step: 0.5,
        min: -1,
        max: 20,
        lazy: false,
      },
      slider_pressure: {
        orientation: "vertical",
        tooltips: false,
        direction: "rtl",
        lazy: false,
        min: 0,
        max: 150,
      },
    };
  },
  methods: {
    async openBasic() {
      try {
        this.listTags = [...this.arrTags];
        await this.selectedDevice;
        this.selectedCountries = { ...this.selectedCountries, ...this.selectedDevice }
        const { device_id, id, install_point_id } = this.selectedDevice;

        const commDetails = await commissioningDetails({ install_point_id: install_point_id || id, device_id })
        const commDetailsData = commDetails.data[0];

        let { alarm_boundaries } = commDetailsData;

        let lower_limit = 0;
        let upper_limit = 0;

        if (alarm_boundaries) {
          if (alarm_boundaries.lower_limit) lower_limit = Number(alarm_boundaries.lower_limit);
          if (alarm_boundaries.upper_limit) upper_limit = Number(alarm_boundaries.upper_limit);
        }

        this.selectedCountries.clickedDevice.slider = ref([lower_limit, upper_limit])

        delete commDetailsData.alarm_boundaries;
        this.selectedCountries = { ...this.selectedCountries, ...commDetailsData }
        const photo_json = {
              'device_image':this.selectedCountries.device_image,
              'set_image':this.selectedCountries.set_image
             }
        this.verPhoto(photo_json);
        this.displayBasic = true;
      } catch (err) {
        this.showErrorServer(err)
      }
      this.newTagsUp = this.selectedDevice.tags
      this.updateList(this.selectedDevice.tags)
    },
    async saveBoundaries() {
      try {
        const { install_point_id, device_id } = this.selectedCountries;

        const updateAlarmBoundariesPayload = {
          install_point_id,
          device_id,
          alarm_boundaries: { lower_limit: 0, upper_limit: 0 }
        }

        if(!this.checkboxChecked) {
          updateAlarmBoundariesPayload.alarm_boundaries = {
            lower_limit: this.selectedCountries.clickedDevice.slider[0],
            upper_limit: this.selectedCountries.clickedDevice.slider[1],
          }
        }

        await updateAlarmes(updateAlarmBoundariesPayload);
        await this.updateTagSetup(this.selectedCountries.install_point_id || this.selectedCountries.id);
        this.showSuccess();
      } catch (e) {
        this.showErrorServer(e);
      }
      setTimeout(() => {
        this.closeBasic();
      }, 250);
      this.emitter.emit('my-event-get-pins-func')
    },
    async verPhoto(data) {
      try {
            this.imagem_1 = data.device_image
            this.imagem_2 = data.set_image   

              } catch (e) {
              throw new Error(`Could not retrieve file from S3: ${e.message}`)
            }
    },
    newTags(data) {
      this.newTagsUp = data;
    },
    closeBasic() {
      this.displayBasic = false;
      this.newTagsUp = {};
    },
  },
  watch: {
    show: function () {
      this.openBasic();
    },
  },
  setup() {
    const myMapRef = ref(null);
    const toast = useToast();
    //OverlayPanel
    const op = ref();
    const selectedProduct = ref();
    const toggle = (event) => {
      op.value.toggle(event);
    };
    let _tags = [];

    //Update Tag
    const updateTagSetup = async (_id) => {
      if(selectedProduct.value){
        for (let n = 0; n < selectedProduct.value.length; n++) {
          _tags.push(selectedProduct.value[n].id_name)
        }
        let tagEdit = {
          id: _id,
          tags: JSON.stringify(_tags).replaceAll(',',';').replaceAll('[','').replaceAll(']','').replaceAll('"','')
        };
        await updateTag(tagEdit)
        _tags = []
      }
    }
    const updateList = (listTags) => {
      selectedProduct.value = listTags
    }

    const showSuccess = () => {
      toast.add({
        severity: "success",
        summary: "Informações do Ponto de Instalação salvas com sucesso!",
        life: 3000,
      });
    };
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 2500});
    }

    return {
      op,
      selectedProduct,
      toggle,
      updateTagSetup,
      updateList,
      myMapRef,
      showSuccess,
      showErrorServer,
    }
  }
});
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
#btn-tag {
  background: white;
  color: #8086C0;
  border-bottom-color: #8086C0;
  padding: 0.1rem;
  border-width: 0px 0px 2px 0px;
}
#btn-tag:hover {
  background: white;
  color: #8086C0;
  border-bottom-color: #8086C0;
  padding: 0.1rem;
  border-width: 0px 0px 2px 0px;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}
P{
  margin-top: 0;
  margin-bottom: 0rem
}
.photo_devices:hover {
  height: 600px;
  margin: 0;
}
.inputDetalhamento {
  width: 35%;
  font-size: 20px;
  height: 25%;
}
.sliders {
  align-self: center;
  width: 35%;
  display: inline-flex;
}
.imgSensor {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
}
.sensoresPI {
  display: flex;
  justify-content: space-evenly;
}
.slider-vertical {
  height: 8rem;
  
}
.infoDevices {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}
input[type="checkbox"] {
    zoom: 1.5;
    vertical-align: sub;
    accent-color: #6eff94;
}
.p-image {
  border-radius: 10px;
}
.titleComponent{
  font-size: 1.5rem;
}
.spaceTag{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 2rem 0rem 2rem;
}
</style>
<style src="@vueform/slider/themes/default.css"> 
</style>