<template>
  <Dialog
    v-model:visible="display"
    :style="{ width: '75vw' }"
    :modal="true"
    @hide="zerarTag()"
  >
    <template #header>
      <h3>Tags Criadas</h3>
    </template>
    <Chip
      style="margin: 0.5rem"
      :key="info.id_name"
      v-for="info in listTags"
      :label="info.label"
      :class="info.color"
      @remove="remover(info.id_name)"
      :style="
        info.color != 'yellow' && info.color != 'aqua' && info.color != 'pink'
          ? `color: white;background-color: ${info.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px;`
          : `color: black;background-color: ${info.color}`
      "
    >
      <div style="padding: 6px">
        {{ info.label }}
        <div
          @click="remover(info.id_name)"
          style="display: contents; cursor: pointer"
        >
          <i class="pi pi-times-circle" style="margin-left: 2px"></i>
        </div>
      </div>
    </Chip>

    <h3 style="margin-top: 2rem">Criar Nova Tag</h3>
    <div class="col-sm-12">
      <div class="form-floating col-sm-12">
        <input
          type="text"
          class="form-control"
          placeholder="Nome"
          autocomplete="off"
          v-model="newTagName"
          maxlength="15"
          aria-describedby="addon-wrapping"
          id="floatingInputGrid"
        />
        <label for="floatingInputGrid">Nome: (máx: 15 caracteres)</label>
      </div>
      <SelectButton
        v-model="color"
        class="col-sm-12"
        :options="listaCores"
        dataKey="value"
        style="margin-top: 1rem"
      >
        <template #option="slotProps">
          <div :class="'square_' + slotProps.option.value"></div>
        </template>
      </SelectButton>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        @click="fecharModal()"
        autofocus
        class="btn btn-cancelar"
      />
      <Button
        label="Criar"
        @click="salvarNovaTag()"
        autofocus
        class="btn btn-filtrar"
      />
    </template>
  </Dialog>
  <div>
    <Dialog v-model:visible="displayMessage" :closable=false :modal=true>
    <template #header style="background-color:red">
      <div style="margin:auto"> 
        <h2 style="color:red">ATENÇÃO !</h2>
      </div>
    </template> 
    <div style="text-align:center; width:400px"> 
      <h3>Deseja excluir a tag ?</h3> 
    </div>
    <template #footer>
      <div style="display: flex;justify-content: space-around;">
        <Button label="Cancelar" @click="closeBasic" icon="pi pi-times" autofocus class="btn cancel" style="background-color: red; color: white"/>     
        <Button label="Excluir" @click="removeTag" icon="pi pi-check" autofocus class="btn delete" style="background-color:  #6eff94; color:white"/>    
      </div>  
    </template>
  </Dialog>
  </div>

  <Toast />
  <div>
    <div class="btn tst" type="button" @click="displayModal">
      Criar TAG
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import SelectButton from 'primevue/selectbutton';
import Chip from "primevue/chip";
import { listTag, createTag, deleteTag} from "../services/crud";
import { store } from '../services/store.js'

export default {
  name: "CompNovaTag",
  components: {
    Toast,
    Dialog,
    Button,
    Chip,
    SelectButton
  },
  data() {
    return {
      display: false,
      displayMessage: false,
      listTags: [],
      color: '',
      newTagName: '',
      tempIdTag:null,
      id_nameTag: '',
    };
  },
  methods: {
    closeBasic() {
      this.displayMessage = false;
    },
    async removeTag() {
      let temp = [];

      this.listTags.filter(item =>{
        if(item.id_name != this.tempIdTag.id_name)
        temp.push(item)
      })
      this.listTags = temp
      this.displayMessage = false;

      await deleteTag(this.tempIdTag)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          this.showSuccessDelete()
          this.tempIdTag = ''
        }
        this.emitter.emit('my-event-remove-tag')
        this.listTags.length >= 10 ? this.deztags = false : this.deztags = true
      })
      .catch((err) => this.showErrorServer(err))
    },
    displayModal() {
      this.display = !this.display;
      let aux = [];
      let listaTags = [];
      const client_id = { client_id: store.id };
      if (client_id.client_id.length > 0 && this.empresa) {
        listTag(client_id).then((response) => {
          aux = response.data;
          for (let i = 0; i < aux.length; i++) {
            listaTags = {
              label: aux[i].name,
              color: aux[i].color,
              id_name: aux[i].id_name,
            };
            this.listTags.push(listaTags);
          }
        });
      }
      if (client_id.client_id.length < 1 || !this.empresa) {
        this.display = !this.display;
        this.showErrorNoClient();
      }
    },
    fecharModal () {
      this.display = !this.display
      this.zerarTag()
    },
    zerarTag(){
      this.listTags= []
    },
    async remover(id_tag){
      const dataDelete = {
        client_id: store.id,
        id_name: id_tag
      }
      this.tempIdTag = dataDelete;
      this.displayMessage = !this.displayMessage;
    },
    async salvarNovaTag() {
      let temp = null;
      this.id_nameTag = this.newTagName.toLocaleLowerCase().replace(/\s/g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, "")

      if(!this.newTagName || !this.newTagName.trim() || !this.id_nameTag || !this.id_nameTag.trim || !this.color.value ){
        this.showError()
      }else{
        const dataForm = {
          name: this.newTagName,
          id_name: this.id_nameTag,
          color: this.color.value,
          client_id: store.id
        }
        await createTag(dataForm)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.newTagName = "";
            temp = {
              label: dataForm.name,
              color: dataForm.color,
              id_name: dataForm.id_name
            }
            this.listTags.push(temp)
          }
          this.emitter.emit('my-event-add-tag')
        })
        .catch((response) => this.showErrorServer(response))
      }
    },
  },
  created() {
    this.emitter.on('my-event-select-multi', (data) => {
      this.empresa = data;
    });
  },
  setup(){
    const listaCores = ref( [
        { label: "Vermelho", value: 'red' },
        { label: "Verde", value: 'green' },
        { label: "Azul", value: 'blue' },
        { label: "Amarelo", value: 'yellow' },
        { label: "Cinza", value: 'gray' },
        { label: "Rosa", value: 'pink' },
        { label: "Roxo", value: 'purple' },
        { label: "Marrom", value: 'brown' },
        { label: "Aqua", value: 'aqua' },
        { label: "Laranja", value: 'orange' },
      ])
   
    const toast = useToast();
    const showError = () => {
      toast.add({severity:'error', summary: 'Error', detail:'Campos Obrigatórios não foram preenchidos!', life: 2500});
    }
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 2500});
    }
    const showsuccess = () => {
      toast.add({severity:'success', detail:"Tag adicionada com sucesso! ", life: 2500});
    }
    const showSuccessDelete = () => {
      toast.add({severity:'success', detail:"Tag removida com sucesso! ", life: 2500});
    }
    const showErrorAlreadyExisted = () => {
      toast.add({severity:'error', detail:"Tag já existente! ", life: 2500});
    }
    const showErrorNoClient = () => {
      toast.add({severity:'error', summary: 'Atenção', detail:'Por favor, selecione um cliente em Ver Como!', life: 2500});
    }
    return{showError,showErrorServer,showsuccess, listaCores,showSuccessDelete, showErrorAlreadyExisted,showErrorNoClient}
  }
};
</script>

<style scoped>
h2 {
  margin: 1rem;
}
.tableBox {
  padding: 1rem 2rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.tst {
    color: black;
    padding: 0px;
    padding-right: 70%;
    margin: 0%;
}
.btn-pos_filtrar {
  border-color: #6eff94;
  background-color: white;
  color: black;
  margin: 1rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}
.btn-pos_download {
  background-color:#6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 12rem;
  font-weight: 400;
  height: 42px;
}
.btn-pos_dowCancel {
  background-color:#DC3545;
  color: black;
  margin: 1rem 0rem;
  width: 12rem;
  font-weight: 400;
  height: 42px;
}
.btn-pos_add {
  background-color: #858585;
  color: #ffffff;
  margin: 1rem 0rem;
  width: 8rem;
  font-weight: 400;
  height: 42px;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem !important;
  width: 10% !important;
  font-weight: 400;
  height: 42px;
}
.btn-cancelar, .btn-cancelar:hover  {
  border-color: red;
  background-color: red;
  color: black;
  margin: 1rem 1rem !important;
  width: 10% !important;
  font-weight: 400;
  height: 42px;
}
.btn-filtrar:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  width: 10% !important;
  margin: 1rem 0rem !important;
  font-weight: 400;
  height: 42px;
}
.inputClass {
  margin: 1rem 0rem;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.square_gray{
  height: 2rem;
  width: 3.5rem;
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}
.square_red{
  height: 2rem;
  width: 3.5rem;
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}
.square_green{
  height: 2rem;
  width: 3.5rem;
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}
.square_blue{
  height: 2rem;
  width: 3.5rem;
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}
.square_yellow{
  height: 2rem;
  width: 3.5rem;
  background: yellow;
  color: white;
  margin-right: 0.5rem !important;
}
.square_pink{
  height: 2rem;
  width: 3.5rem;
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}
.square_purple{
  height: 2rem;
  width: 3.5rem;
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}
.square_brown{
  height: 2rem;
  width: 3.5rem;
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}
.brown{
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}
.aqua{
  background: aqua ;
  color: white;
  margin-right: 0.5rem !important;
}
.square_aqua{
  height: 2rem;
  width: 3.5rem;
  background: aqua ;
  color: white;
  margin-right: 0.5rem !important;
}
.square_orange{
  height: 2rem;
  width: 3.5rem;
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}
.orange{
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}
.gray{  
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}
.purple{
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}
.red{
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}.green{
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}.blue{
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}.yellow{
  background: yellow;
  color: white;
  margin-right: 0.5rem !important;
}
.pink{
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}
.cancel{
  border: red 1px solid;
  background-color: red;
  color: white;
}
.cancel:hover{
  border: red 1px solid;
  background-color: red;
  color: white;
}
.delete{
  border: #6eff94 1px solid;
  background-color: #6eff94;
  color: white;
}
.delete:hover{
  border: #6eff94 1px solid;
  background-color: #6eff94;
  color: white;
}
::v-deep(.multiselect-custom) {
  .multiselect-caret {
    margin-right: -1rem;
  }
}
</style>

