<template>
  <Toast />
  <div class="allScreen" id="dashContent">
    <button v-if="this.controlSidebar" class="openbtn_inicio" @click="controleCollpaseFiltro()"><i
        class="gg-push-chevron-left"></i></button>
    <button v-else class="closebtn_inicio" @click="controleCollpaseFiltro()"><i
        class="gg-push-chevron-right"></i></button>
    <div class="col-sm-2 pontosInstall sidebar" id="mySidebar">
      <div v-if="empresa">
        <p
          class="labelMultiselect"
          v-if="modelGroup === 'a1' || modelGroup === 'a2'"
        >
          Comunicaram
        </p>
        <p
          class="labelMultiselect"
          v-if="modelGroup === 'b1' || modelGroup === 'b2' || modelGroup === 'b3' || modelGroup === 'b4'"
        >
          Não Comunicaram
        </p>
        <Multiselect :columns="{ container: 12, label: 4, wrapper: 6 }" v-model="modelGroup" class="multiselect-custom"
          placeholder="Filtrar por" :groups="true" :options="[{
            label: 'Comunicaram...', options: [
              { label: 'todas às vezes previstas no dia anterior', value: 'a1' },
              { label: ' pelo menos uma vez no dia anterior', value: 'a2' }
            ]
          }, {
            label: 'Não Comunicaram...', options: [
              { label: 'no último dia', value: 'b1' },
              { label: 'nos últimos 3 dias', value: 'b2' },
              { label: 'nos últimos 7 dias', value: 'b3' },
              { label: 'nos últimos 30 dias', value: 'b4' }
            ]
          }]" />
        <div style="display: flex; justify-content: space-evenly;">
          <div class="col-sm-6">
            <button class="btn btn-filtrar submit" type="button" @click="filterConnectivity">
              Buscar
            </button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-limpar submit" type="button" @click="resetFilter()">
              Limpar
            </button>
          </div>
        </div>
        <div class="col-sm-12">
          <div style="margin-top: 15px">
            <input type="radio" name="aaa" value="name" v-model="radioFilter" @click="cleanFilter()">
            <label for="" style="margin-right: 20px;">Nome</label>
            <input type="radio" name="aaa" value="tags" v-model="radioFilter">
            <label for="">Tag</label>
            <div v-if="radioFilter === 'tags'"
              style="display: flex;flex-wrap: wrap;justify-content:start;padding: 15px">
              <div v-for="f_tag in listTags" :key="f_tag.id_name">
                <span v-if="f_tag.selected" class="badge rounded-pill"
                  :style="f_tag.color != 'yellow' && f_tag.color != 'aqua' && f_tag.color != 'pink' ? `color: white;background-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px;` : `color: black;background-color: ${f_tag.color};font-size: 14px;margin-bottom: 5px; cursor:pointer;`"
                  @click="selectMonomer(f_tag)"> {{ f_tag.name }}</span> &nbsp;

                <span v-if="!f_tag.selected" class="badge rounded-pill"
                  :style="`color: ${f_tag.color};border-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px; cursor:pointer;`"
                  @click="selectMonomer(f_tag)"> {{ f_tag.name }}</span> &nbsp;
              </div>
            </div>
          </div>
          <Listbox
            class="listBox"
            id="listBoxID"
            :options="products"
            v-model="this.selectedCountries"
            :optionLabel="radioFilter"
            emptyMessage="Sem Dados"
            emptyFilterMessage="Nenhum dado encontrado"
            style="width: 100%"
            :filter="radioFilter == 'tags' ? false : true"
            ref="filter"
            :select-on-focus="true"
            :auto-option-focus="true"
            :optionDisabled="(option) => option.device_id == this.selectedCountries.device_id"
            @click="showResultAgreg = false"
          >
            <template #option="slotProps">
              <div class="country-item">
                <div class="numPontoInstal">
                  <div class="fontListPontoInstall" id="my-listBox">
                    {{ slotProps.option.name }}
                  </div>
                  <div class="device-type-icons-container">
                    <span>
                      <img v-if="slotProps.option.type === 'flow'" src='../assets/flowOff.png' alt="device flow icon"
                        class="device-type-icon">
                      <img v-else-if="slotProps.option.type === 'pressure'" src='../assets/pressureOff.png'
                        alt="device pressure icon" class="device-type-icon">
                      <img v-else src='../assets/noiseOff.png' alt="device noise icon" class="device-type-icon">
                    </span>
                  </div>
                </div>
                <div v-if="slotProps.option.tags">
                  <!-- <span v-if="slotProps.option.tags.length > 0" > </span> <br> -->
                  <div style="display: flex;flex-wrap: wrap;">
                    <div v-for="tag in slotProps.option.tags" :key="tag.id">
                      <!-- <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="tag.name" :style="tag.color != 'yellow' && tag.color != 'aqua' && tag.color != 'pink' ? `width: 45px;background-color: ${tag.color}; color: white; `:`width: 45px;background-color: ${tag.color}; color: black; `">{{tag.name.slice(0,4)}}.</span> &nbsp; -->
                      <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        :title="tag.name"
                        :style="tag.color != 'yellow' && tag.color != 'aqua' && tag.color != 'pink' ? `background-color: ${tag.color}; color: white; ` : `background-color: ${tag.color}; color: black; `">{{
                          tag.name
                        }}</span>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Listbox>
        </div>
      </div>
      <div v-else>
        <h5 style="margin-top: 8vh">Por favor, clique em Ver Como e selecione um cliente.</h5>
      </div>
    </div>
    <div v-if="this.selectedCountries.name === ''" class="col-sm-12 main_header" id="main_header">
      <div class="col-sm-9" style="padding-top: 1rem;"></div>
      <div class="col-sm-12" v-if="showMap">
        <GMapMap class="mmm" ref="myMapRef" :center="center" :zoom="15" map-type-id="terrain" :options="options">
          <!-- :icon="marker.devices[0].alarm_boundaries.upper_limit > 5 ? markerOptionsR : markerOptionsBk" -->
          <GMapMarker :key="marker.index" v-for="marker in mapPins" :position="{ lat: marker.latPair, lng: marker.lngPair }"
            @click="filterMap(marker)" @mouseover="openMarker(marker.id)"
            :icon="marker.flagAlarm == 'green' ? markerOptionsGreen : marker.flagAlarm == 'yellow' ? markerOptionsYellow : markerOptionsRed"
            :clickable="true" />
          <GMapCircle :key="marker.index" v-for="marker in mapPins" :radius="Math.sqrt(0.1) * 10"
            :center="{ lat: marker.latPair, lng: marker.lngPair }"
            :options="marker.flagGroup ? circleOptionsTrue : circleOptionsFalse" />
        </GMapMap>
      </div>
      <div class="espacoLegenda" style="display:flex">
        <div class="legenda">
          <img src='../assets/pinConectividade/pinVerde.png' alt="Green pin indicating a good connectivity rate"
            width="45" height="45">
          <a>Comunicou nas últimas 24hs</a>
        </div>
        <div class="legenda">
          <img src='../assets/pinConectividade/pinAmarelo.png'
            alt="Yellow pin indicating warning status of connectivity rate" width="45" height="45">
          <a>Comunicou entre 24hs e 7 dias</a>
        </div>
        <div class="legenda">
          <img src='../assets/pinConectividade/pinVermelho.png'
            alt="Red pin indicating critical status of connectivity rate" width="45" height="45">
          <a>Não comunica há mais de 7 dias</a>
        </div>
      </div>
    </div>
    <!-- PONTO DE INSTALAÇÃO SELECIONADO -->
    <div v-else class="col-sm-12 main_header">
      <div class="col-sm-12" style="display:flex; justify-content: space-evenly;margin-bottom: 1rem">
        <Card class="box_indicadores col-sm-2 cardStyle" id="codigoIntalacaoBox">
          <template #title>
            Código de Instalação
          </template>
          <template #content>
            <div class="contentCodInst">{{ selectedCountries.name }}</div>
          </template>
        </Card>
        <Card id="box_font_menor" class="box_indicadores col-sm-2 cardStyle">
          <template #title>
            Estado da Conectividade
          </template>
          <template #content>
            <span class="estadoConectvidade" v-if="pinCardColor === 'green'">
              <img src='../assets/pinConectividade/pinVerde.png' alt="Green pin indicating a good connectivity rate"
                width="45" height="45">
              Comunicou nas últimas 24hs
            </span>
            <span class="estadoConectvidade" v-if="pinCardColor === 'yellow'">
              <img src='../assets/pinConectividade/pinAmarelo.png'
                alt="Yellow pin indicating warning status of connectivity rate" width="45" height="45">
              Comunicou entre 24hs e 7 dias
            </span>
            <span class="estadoConectvidade" v-if="pinCardColor === 'red'">
              <img src='../assets/pinConectividade/pinVermelho.png'
                alt="Red pin indicating critical status of connectivity rate" width="45" height="45">
              Não comunica há mais de 7 dias
            </span>
          </template>
        </Card>
        <Card v-if="!demo" id="box_font_menor" class="box_indicadores col-sm-3 cardStyle">
          <template #title>
            Endereço da Instalação
          </template>
          <template #content>
            <div style="padding: 0rem 1.5rem;">
              {{
                (selectedCountries.address ? selectedCountries.address + ", " : "") +
                (selectedCountries.number ? selectedCountries.number + " , " : "") +
                (this.selectedCountries.city ? selectedCountries.city + " - " : "") +
                (selectedCountries.state ? selectedCountries.state : "")
              }}
            </div>
          </template>
        </Card>
        <Card class="box_indicadores col-sm-2 cardStyle">
          <template #title>
            Nível de Bateria
          </template>
          <template #content>
            <img v-if="selectedCountries.battery_level == 'Ok'" src="../assets/battery-full.png"
              class='levelBat flag flag-'>
            <img v-if="selectedCountries.battery_level == 'Regular'" src="../assets/battery.png"
              class='levelBat flag flag-'>
            <img v-if="selectedCountries.battery_level == 'Baixa'" src="../assets/battery-empty.png"
              class='levelBat flag flag-'>
            <span style="margin: 0.3rem 1.5rem;">{{
              selectedCountries.battery_level == 'Não informado' ?
                "Sem dados" : ''
            }}</span>
          </template>
        </Card>
      </div>
      <div class="col-sm-12 mapaGrafico">
        <div class="col-sm-6 mapaDisplay">
          <GMapMap class="mmm2" ref="myMapRef" :center="{ lat: selectedCountries.lat, lng: selectedCountries.lng }"
            :zoom="25" map-type-id="terrain" :options="options">
            <GMapMarker v-for="marker in mapPins" :key="marker.index" :position="{ lat: marker.latPair, lng: marker.lngPair }"
              @click="filterMap(marker)"
              :icon="marker.flagAlarm == 'green' ? markerOptionsGreen : marker.flagAlarm == 'yellow' ? markerOptionsYellow : markerOptionsRed"
              :animation="marker.device_id == selectedCountries.device_id ? 1 : 0"
              :clickable="marker.device_id !== selectedCountries.device_id ? true : false" />
            <GMapCircle :key="marker.index" v-for="marker in mapPins" :radius="Math.sqrt(0.1) * 10"
              :center="{ lat: marker.latPair, lng: marker.lngPair }"
              :options="marker.flagGroup ? circleOptionsTrue : circleOptionsFalse" />
          </GMapMap>
          <!-- Div com o Heatmap -->
        </div>
        <div class="col-sm-6 graficoDisplay">
          <div class="tabs">
            <TabView :lazy="true" v-model:activeIndex="tabIndex">
              <TabPanel header="Conectividade">
                <div>
                  <CalHeatMaps :data-inicial="`${range.start}`" :dataF="dataF" :dataN="dataN" :dataP="dataP"
                    :selectedDevice="selectedCountries" :tabX="tabName" />
                </div>
              </TabPanel>
              <TabPanel header="Log de Telemetria">
                <LogTel :selectedDevice="selectedCountries" />
              </TabPanel>
              <TabPanel header="Aviso" v-if="tabIndex == 3">
                <div style="font-size: 20px; text-align: center;">
                  Não há aviso de alarme disparado.
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
      <div class="espacoLegendaSelecionado">
        <span class="legenda">
          <img src='../assets/pinConectividade/pinVerde.png' alt="Green pin indicating a good connectivity rate"
            width="45" height="45">
          Comunicou nas últimas 24hs
        </span>
        <span class="legenda">
          <img src='../assets/pinConectividade/pinAmarelo.png'
            alt="Yellow pin indicating warning status of connectivity rate" width="45" height="45">
          Comunicou entre 24hs e 7 dias
        </span>
        <span class="legenda">
          <img src='../assets/pinConectividade/pinVermelho.png'
            alt="Red pin indicating critical status of connectivity rate" width="45" height="45">
          Não comunica há mais de 7 dias
        </span>
      </div>
      <Toast />
      <Toast position="top-left" group="tl" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import "v-calendar/dist/style.css";
import Listbox from "primevue/listbox";
import { store } from "../services/store.js";
import Multiselect from "@vueform/multiselect";
import {
  commmunication,
  getConnectivityRate,
  installPointsData,
  getBatteryAndAlarms,
  getInstallPointsFindOne,
} from "../services/dashboard.js";
import moment from "moment-timezone";
import CalHeatMaps from "../components/CalHeatMaps.vue";
import LogTel from "../components/LogTel.vue";
moment.locale("en-us");
import { useLoading } from "vue-loading-overlay";
const $loading = useLoading();
import Card from 'primevue/card';
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { listTag } from "../services/crud.js";
import _ from 'lodash';

import { StyleMap, StyleMapFair } from "../utils/StyleMap";

const mapMarkerGreen = require("../assets/pinConectividade/pinVerde.png");
const mapMarkerYellow = require("../assets/pinConectividade/pinAmarelo.png");
const mapMarkerRed = require("../assets/pinConectividade/pinVermelho.png");

export default defineComponent({
  name: "Dashboard",
  components: {
    Multiselect,
    Listbox,
    Toast,
    TabView,
    Card,
    TabPanel,
    CalHeatMaps,
    LogTel,
  },
  data() {
    return {
      showMap: false,
      pinCardColor: [],
      connectivityRate: [],
      modelGroup: null,
      checkMulti: false,
      checkTypes: null,
      setMapVoid: [],
      auxTags: [],
      tempMeter: null,
      countTagCall: 0,
      listTags: [],
      radioFilter: "name",
      isSuper: store.type_user == 1 ? true : false,
      markerOptionsRed: {
        url: mapMarkerRed,
        scaledSize: { width: 33, height: 33, f: "px", b: "px" },
      },
      markerOptionsGreen: {
        url: mapMarkerGreen,
        scaledSize: { width: 33, height: 33, f: "px", b: "px" },
      },
      markerOptionsYellow: {
        url: mapMarkerYellow,
        scaledSize: { width: 33, height: 33, f: "px", b: "px" },
      },
      circleOptionsTrue: {
        strokeColor: "#6eff94",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6edd94",
        fillOpacity: 0.35,
      },
      circleOptionsFalse: {
        strokeColor: "#fff",
        strokeOpacity: 0.01,
        strokeWeight: 2,
        fillColor: "#fff",
        fillOpacity: 0.01,
      },
      center: { lat: -23.4775, lng: -47.4241 },
      rangeBack: {},
      range: {
        start: new Date(this.aMonthAgo()),
        end: new Date(),
      },
      products: null,
      backupInstallPointsData: null,
      empresa: null,
      mapPins: [],
      controlSidebar: false,
      selectedCountries: {
        name: "",
        id: null,
      },
      temp: null,
      flag: null,
      dataF: null,
      dataN: null,
      dataP: null,
      tabIndex: 0,
      tabName: null,
      tabF: null,
      tabN: null,
      tabP: null,
      demo: JSON.parse(localStorage.getItem("userData")).demo,
      options: {
        styles: JSON.parse(localStorage.getItem("userData")).demo ? StyleMapFair : StyleMap,
      },
      noSectorArr : [
          {
              "id_name": "ntag",
              "name": "NTAG",
              "color": "black"
          }
      ]
    };
  },
  methods: {
    cleanFilter() {
      this.$refs.filter.filterValue = '';
    },
    async treatSelectedCountries(newSelectedDevice) {
      const { device_id, id, name, client_id, lat, lng, flagAlarm, flagGroup,
        index, latPair, lngPair, type, tags } = newSelectedDevice;

      const batteryAndAlarms = await getBatteryAndAlarms(device_id);
      const { data } = batteryAndAlarms;

      const installPointAdress = await getInstallPointsFindOne({ id, name, client_id });
      const { address, number, city, state } = installPointAdress.data;

      this.selectedCountries.address = address;
      this.selectedCountries.number = number;
      this.selectedCountries.city = city;
      this.selectedCountries.state = state;
      this.selectedCountries.battery_level = data.battery_level;
      this.selectedCountries.id = id;
      this.selectedCountries.lat = lat;
      this.selectedCountries.lng = lng;
      this.selectedCountries.device_id = device_id;
      this.selectedCountries.flagAlarm = flagAlarm;
      this.selectedCountries.flagGroup = flagGroup;
      this.selectedCountries.index = index;
      this.selectedCountries.latPair = latPair;
      this.selectedCountries.lngPair = lngPair;
      this.selectedCountries.type = type
      this.selectedCountries.tags = tags;
      this.selectedCountries.name = name;
    },
    treatResponseInstallPointsData(responseIPData) {
      return responseIPData.map((ipData) => ({ ...ipData, lng: ipData.long }))
    },
    controleCollpaseFiltro() {
      let tempAnimTime = 300;
      this.sidebarControl = {
        animationTime: tempAnimTime,
        frameInterval: 10,
        width: 250,
        iterations: null,
        diffPerIteration: null,
      };
      this.sidebarControl.iterations = Math.floor(this.sidebarControl.animationTime / this.sidebarControl.frameInterval);
      this.sidebarControl.diffPerIteration = this.sidebarControl.width / this.sidebarControl.iterations;
      this.controlSidebar ? this.closeNav() : this.openNav()
    },
    openNav() {
      this.sidebarControl.animationTime = 30;
      document.getElementById("mySidebar").style.width = "255px";
      let elapsedTime = 0;
      document.getElementById("mySidebar").style.width = "255px";
      let animationInterval = setInterval(() => {
        elapsedTime += this.sidebarControl.frameInterval;
        if (elapsedTime > this.sidebarControl.animationTime) {
          clearInterval(animationInterval);
          document.getElementById("dashContent").style.marginLeft = "255px";
          this.controlSidebar = true;
          this.emitter.emit('my-event-controlSidebar', true);
          return;
        }
        const currentMarginLeft = parseFloat(document.getElementById("dashContent").style.marginLeft.replace('px', ''));
        document.getElementById("dashContent").style.marginLeft = (currentMarginLeft + this.sidebarControl.diffPerIteration).toString() + 'px';
      }, this.sidebarControl.frameInterval);
    },
    closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      let elapsedTime = 0;
      let animationInterval = setInterval(() => {
        elapsedTime += this.sidebarControl.frameInterval;
        if (elapsedTime > this.sidebarControl.animationTime) {
          clearInterval(animationInterval);
          document.getElementById("dashContent").style.marginLeft = "0";
          this.controlSidebar = false
          this.emitter.emit('my-event-controlSidebar', false)
          return;
        }
        const currentMarginLeft = parseFloat(document.getElementById("dashContent").style.marginLeft.replace('px', ''));
        document.getElementById("dashContent").style.marginLeft = (currentMarginLeft - this.sidebarControl.diffPerIteration).toString() + 'px';
      }, this.sidebarControl.frameInterval);
    },
    geolocate() {
      if (!navigator.geolocation) return;
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      });
    },
    centralize() {
        const loader = $loading.show({});
        if (this.$refs.myMapRef) {
          this.$refs.myMapRef.$mapPromise.then(() => {
            let bounds = new window.google.maps.LatLngBounds();
            Object.values(this.mapPins).forEach((sample) =>
              bounds.extend({ lat: sample.lat, lng: sample.lng })
            );
            this.$refs.myMapRef.$mapPromise
              .then((map) => {
                map.panTo(bounds.getCenter());
                map.fitBounds(bounds);
              })
              .catch(function (e) {
                console.log("message", e);
              });
          });
        }
        loader.hide();
    },
    async filterMap(data) {
      const loader = $loading.show({});
      this.showMap = true;
      if (!this.empresa) this.empresa = store.client_id;

      if (this.isSuper && store.last_search.length > 0) {
        this.empresa = store.last_search;
      } else if (this.isSuper != 1) {
        this.empresa = store.client_id;
      }

      if (!data) {
        this.mapPins = [];
        this.selectedCountries.name = '';

        const client_id = this.empresa ? this.empresa : store.last_search;
        store.id = this.empresa;

        const payload = { client_id }

        await installPointsData(payload)
          .then(async (response) => {
            const treatedResponse = this.treatResponseInstallPointsData(response.data);

            if (treatedResponse.length) {
              const ipAlreadyAccounted = new Set()

              for (let i in treatedResponse) {
                const ipData = treatedResponse[i];
                const repeatedIP = ipAlreadyAccounted.has(ipData.install_point_id);

                const lat = repeatedIP
                  ? (parseFloat(`${ipData.lat}`.substr(0, 9)) + 0.000004)
                  : (parseFloat(`${ipData.lat}`.substr(0, 9)) - 0.000004);

                const lng = repeatedIP
                  ? (parseFloat(`${ipData.lng}`.substr(0, 9)) + 0.000004)
                  : (parseFloat(`${ipData.lng}`.substr(0, 9)) - 0.000004);

                this.mapPins.push({
                  device_id: ipData.device_id,
                  latPair: parseFloat(lat),
                  lngPair: parseFloat(lng),
                  flagGroup: repeatedIP,
                  lat: ipData.lat,
                  lng: ipData.lng,
                  id: ipData.install_point_id,
                  name: ipData.name,
                  tags: (!ipData.tags_dados || ipData.tags_dados.length === 0) && treatedResponse.length > 1000 ? this.noSectorArr : ipData.tags_dados,
                  type: ipData.type,
                  index: i++,
                });

                ipAlreadyAccounted.add(ipData.install_point_id);
              }

              this.centralize();

              await this.showConect().catch(() => this.showErrorServer('Erro ao acessar dados de conectividade!'));
              this.products = _.cloneDeep(this.mapPins);
              this.backupInstallPointsData = _.cloneDeep(this.mapPins);
            }
          })
          .catch(() => {
            if (!this.empresa) {
              this.showErrorFiltro()
            } else {
              this.showErrorServer()
            }
          });

        if (this.tempMeter) {
          this.$refs.filter.filterValue = this.tempMeter;
        }

        loader.hide();
        if (this.products) {
          this.radioFilter = this.products.length > 1000 ? "tags" : "name";
          if (this.listTags.length > 0) {
            let tagSelected = this.listTags[0];
            tagSelected.selected = false;
            setTimeout(() => {
              this.selectMonomer(tagSelected);
            }, 500);
          }
        }

        return;

      } else if (data) {
        this.auxTags = [];

        this.listTags.forEach((tag) => tag.selected = false)

        if (this.$refs.filter) {
          this.$refs.filter.filterValue = "";
        }

        if (this.empresa == null) {
          this.empresa = store.client_id;
        }

        // Entrando direto pelo email Inicio
        if (this.isSuper && store.last_search.length > 0) {
          this.empresa = store.last_search;
        } else if (this.isSuper != 1) {
          this.empresa = store.client_id;
        }

        try {
          await this.treatSelectedCountries(data)
          this.getCommunicationData();
        } catch (e) {
          this.showErrorServer()
        }

        this.checkPinColor(data);
        loader.hide();
        this.showMap = true;
        return;
      }
    },
    aMonthAgo() {
      const dateStart = new Date();
      return dateStart.getTime() - 30 * 24 * 3600 * 1000;
    },
    async getCommunicationData() {
      let xx = {};
      this.dataF = null;
      this.dataN = null;
      this.dataP = null;
      this.tabF = true;
      this.tabN = true;
      this.tabP = true;
      if (this.selectedCountries) {
        xx = {};
        let data = {
          "start_date": this.range.start,
          "final_date": this.range.end,
          "device_id": this.selectedCountries.device_id
        };

        const allEvents = await commmunication(data).catch(() => this.showErrorServer('Erro ao acessar dados de conectividade!'));
        const aux = allEvents.data.data;

        if (aux) {
          Object.entries(aux).forEach(([k, v]) => {
            let aa = (Date.parse(k) / 1000) + (new Date(k).getTimezoneOffset() * 60);
            xx[aa] = v;
          });
        }

        if (this.selectedCountries.type == "flow" && Object.keys(xx).length > 0) {
          this.dataF = xx;
          this.tabF = false;
          this.tabIndex = 0;
        }
        else if (this.selectedCountries.type == "noise" && Object.keys(xx).length > 0) {
          this.dataN = xx;
          this.tabN = false;
          this.tabIndex = 0;
        }
        else if (this.selectedCountries.type == "pressure" && Object.keys(xx).length > 0) {
          this.dataP = xx;
          this.tabP = false;
          this.tabIndex = 0;
        }
      }
    },
    getIndexTab() {
      if (this.tabIndex == 0) {
        this.tabName = "almD";
      }
    },
    upMap(data) {
      setTimeout(() => {
        if (this.$refs.myMapRef) {
          this.$refs.myMapRef.$mapPromise
          .then(() => {
            const bounds = new window.google.maps.LatLngBounds()
            if (data.length) {
              data.forEach((ipData) => bounds.extend({ lat: Number(ipData.lat), lng: Number(ipData.lng) }));
              this.$refs.myMapRef.$mapPromise
              .then((map) => {
                map.panTo(bounds.getCenter());
                map.fitBounds(bounds);
              })
            }
          })
          .catch(function (e) {
            console.log("message", e);
          });
        }
      }, 900);
    },
    selectMonomer: async function (monomer) {
      monomer.selected = !monomer.selected;
      const { selected } = monomer;

      if (selected) {
        this.auxTags.push(monomer.id_name)

        const ipFilteredByTags = this.backupInstallPointsData
          .filter(({ tags }) => {
            if (tags) return tags.some(({ id_name }) => new Set(this.auxTags).has(id_name));
            return false;
          });

        this.products = _.cloneDeep(ipFilteredByTags);
        this.mapPins = _.cloneDeep(ipFilteredByTags);
        this.upMap(_.cloneDeep(ipFilteredByTags));

      } else if (!selected) {
        this.auxTags = this.auxTags.filter((id_name) => id_name !== monomer.id_name);

        if (this.auxTags.length) {
          const ipFilteredByTags = this.backupInstallPointsData
          .filter(({ tags }) => {
            if (tags) return tags.some(({ id_name }) => new Set(this.auxTags).has(id_name));
            return false;
          });

          this.products = _.cloneDeep(ipFilteredByTags);
          this.mapPins = _.cloneDeep(ipFilteredByTags);
          this.upMap(_.cloneDeep(ipFilteredByTags));

        } else {
          this.products = _.cloneDeep(this.backupInstallPointsData);
          this.mapPins = _.cloneDeep(this.backupInstallPointsData);
          this.upMap(_.cloneDeep(this.backupInstallPointsData));
        }
      }
    },
    async listAllTags() {
      //Listagem Tag
      if (this.countTagCall == 0) {
        this.countTagCall++;
        let aux = [];
        let listaTags = [];
        let tempTags = [];
        this.listTags = [];
        const client_id = { "client_id": this.isSuper && this.empresa ? this.empresa : this.isSuper && !this.empresa ? store.last_search : store.client_id };
        let noSector = {
            "client_id": store.client_id,
            "id_name": "ntag",
            "name": "NTAG",
            "color": "black",
            "tag_master": null
        };
        await listTag(client_id)
          .then((response) => {
            aux = response.data;
            if (aux.length > 10) {
                aux.push(noSector);
              }
            const seen = new Set(); 
            for (let i = 0; i < aux.length; i++) {
                if (!seen.has(aux[i].id_name)) {
                  seen.add(aux[i].id_name); 
                  listaTags = {
                    name: aux[i].name,
                    color: aux[i].color,
                    id_name: aux[i].id_name,
                    selected: false,
                  }
                  tempTags.push(listaTags)
                }
              }
          })
        if (!this.isSuper) {
          this.countTagCall--;
        }
        this.listTags = tempTags;
      } else {
        this.countTagCall--;
      }
    },
    resetFilter() {
      if (this.backupInstallPointsData && this.backupInstallPointsData.length) {
        this.products = _.cloneDeep(this.backupInstallPointsData);
        this.mapPins = _.cloneDeep(this.backupInstallPointsData);
      }

      this.modelGroup = null;

      this.selectedCountries = {
        name: "",
        id: null,
      };

      this.centralize();
    },
    async showConect() {
      const payload = {
        client_id: this.empresa,
      }

      await getConnectivityRate(payload)
        .then(({ data: { data: [connRate] } }) => {
          this.connectivityRate = { ...connRate };
          this.paintPins();
        })
    },
    paintPins() {
      const {
        atLeastOneCommunicationYesterday,
        fullCommunicationYesterday,
        notCommunicationYesterday,
        notCommunicationThreeDaysAgo,
        // notCommunicationSevenDaysAgo,
      } = this.connectivityRate;

      const greenCommunication = new Set([...atLeastOneCommunicationYesterday, ...fullCommunicationYesterday]);
      const yellowCommunication = new Set(
        [...notCommunicationYesterday, ...notCommunicationThreeDaysAgo]
      );

      if (this.mapPins.length) {
        this.mapPins.forEach((ipData) => {
          const { device_id } = ipData;

          if (greenCommunication.has(device_id)) {
            ipData.flagAlarm = 'green';
            return;
          }

          if (yellowCommunication.has(device_id)) {
            ipData.flagAlarm = 'yellow';
            return;
          } else {
            ipData.flagAlarm = 'red';
          }
        })
      }
    },
    async filterConnectivity() {
      const {
        fullCommunicationYesterday,
        atLeastOneCommunicationYesterday,
        notCommunicationYesterday,
        notCommunicationThreeDaysAgo,
        notCommunicationSevenDaysAgo,
        notCommunicationThirtyDaysAgo,
      } = this.connectivityRate;

      const notCommThreeDaysAgo = [...notCommunicationThreeDaysAgo, ...notCommunicationSevenDaysAgo, ...notCommunicationThirtyDaysAgo];
      const notCommSevenDaysAgo = [...notCommunicationSevenDaysAgo, ...notCommunicationThirtyDaysAgo];
      const notCommYesterday = [
        ...notCommunicationYesterday,
        ...notCommunicationThreeDaysAgo,
        ...notCommunicationSevenDaysAgo,
        ...notCommunicationThirtyDaysAgo
      ];

      if (this.modelGroup == 'a1') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(fullCommunicationYesterday).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      } else if (this.modelGroup == 'a2') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(atLeastOneCommunicationYesterday).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      } else if (this.modelGroup == 'b1') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(notCommYesterday).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      } else if (this.modelGroup == 'b2') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(notCommThreeDaysAgo).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      } else if (this.modelGroup == 'b3') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(notCommSevenDaysAgo).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      } else if (this.modelGroup == 'b4') {
        const filtered = this.backupInstallPointsData.filter((ipData) => new Set(notCommunicationThirtyDaysAgo).has(ipData.device_id))
        this.products = _.cloneDeep(filtered);
        this.mapPins = _.cloneDeep(filtered);
      }

      this.selectedCountries = {
        name: "",
        id: null,
      };
      this.centralize();
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    checkPinColor(selectedCountries) {
      const connectivityRateData = { ...this.connectivityRate };

      const {
        atLeastOneCommunicationYesterday,
        fullCommunicationYesterday,
        notCommunicationYesterday,
        notCommunicationThreeDaysAgo,
        // notCommunicationSevenDaysAgo,
      } = connectivityRateData;

      const greenCommunication = new Set([...atLeastOneCommunicationYesterday, ...fullCommunicationYesterday]);
      const yellowCommunication = new Set([...notCommunicationYesterday, ...notCommunicationThreeDaysAgo]);

      const { device_id } = selectedCountries;

      if (greenCommunication.has(device_id)) {
        this.pinCardColor = 'green';
        return;
      }

      if (yellowCommunication.has(device_id)) {
        this.pinCardColor = 'yellow';
        return;
      } else {
        this.pinCardColor = 'red';
        return;
      }
    }
  },
  async mounted() {
    this.rangeBack = this.range;
    this.tabName = "almD";
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZoneValue = moment().tz(timeZone).format('z')
    store.local = Math.abs(parseInt(timeZoneValue));
    store.local_country = Intl.DateTimeFormat().resolvedOptions().locale;
    if (this.isSuper && store.last_search.length > 0) {
      this.filterMap();
      this.listAllTags();
    } else if (this.isSuper == false) {
      this.filterMap();
      this.listAllTags();
    }

    this.controleCollpaseFiltro();
  },

  created() {
    this.emitter.on('my-event-select-client', (data) => {
      if (data) {
        store.last_search = data;
        store.client_id = data;
        setTimeout(() => this.checkMulti = !this.checkMulti, 10);
      }
    })
    this.emitter.on('my-event-add-tag', () => {
      this.filterMap();
      this.listAllTags();
    }),
    this.emitter.on('my-event-remove-tag', () => {
      this.filterMap();
      this.listAllTags();
    }),
    this.emitter.on('my-event-select-multi', (data) => {
      this.empresa = data;
      store.client_id = data;
      store.last_search = data;
      this.listAllTags();
    })
  },

  watch: {
    tabIndex: function () {
      this.getIndexTab();
    },
    selectedCountries: async function (selectedCountries) {
      if (selectedCountries.name && selectedCountries.id) {
        const { device_id, id, name, client_id } = selectedCountries;

        const batteryAndAlarms = await getBatteryAndAlarms(device_id);
        const { data: batteryAndAlarmsData } = batteryAndAlarms;
        selectedCountries.battery_level = batteryAndAlarmsData.battery_level;

        const installPointAdress = await getInstallPointsFindOne({ id, name, client_id });
        const { address, number, city, state } = installPointAdress.data;
        selectedCountries.address = address;
        selectedCountries.number = number;
        selectedCountries.city = city;
        selectedCountries.state = state;

        this.getCommunicationData();
        this.checkPinColor(selectedCountries);
      }

      setTimeout(() => {
      }, 500);
    },
    checkMulti: async function () {
      this.filterMap();
      this.listAllTags();
      this.auxTags = [];
    },
  },

  setup() {
    const myMapRef = ref(null);
    const toast = useToast();
    const showErrorServer = (errorMessage) => {
      toast.add({ severity: 'error', summary: 'Error', detail: errorMessage || "Erro ao acessar o servidor!", life: 2500 });
    }
    const displayPosition = ref(false);
    const position = ref('center');
    const closePosition = () => {
      displayPosition.value = false;
    };


    return {
      displayPosition, position,
      closePosition,
      showErrorServer,
      myMapRef,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css">
.multiselect {
  width: 25% !important;
}
</style>
<style src="primevue/resources/primevue.min.css">

</style>
<style src="primevue/resources/themes/saga-blue/theme.css">

</style>
<style scoped>
.p-button:enabled:hover {
  background: #0d89ec00;
  color: #000000;
  border-color: #0d89ec00;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.gray {
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}

.brown {
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}

.pink {
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}

.purple {
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}

.orange {
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}

.aqua {
  background: aqua;
  color: black;
  margin-right: 0.5rem !important;
}

.red {
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}

.green {
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}

.blue {
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}

.yellow {
  background: yellow;
  color: black;
  margin-right: 0.5rem !important;
}
.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

.px-2 {
  width: 95%;
}

.main_header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: fit-content;
  margin: -1rem 0rem 0rem 0rem;
  background-color: #dee2e6;
  padding-left: .5rem;
  padding-top: 1rem;
}
p {
  margin-bottom: 0px;
  color: black;
}
.mmm {
  width: 98.5%;
  height: 72vh;
  border-radius: 3%;
  overflow: hidden;
  margin: 0.5rem 0.5rem
}

.mmm2 {
  width: 95%;
  height: 53vh;
  border-radius: 3%;
  overflow: hidden;
  margin-left: 2.5%;
  border: 4px solid white;
}
.mapGrafico {
  display: flex;
  margin-top: 2.5rem;
}

label {
  margin-left: 0.3rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 3px;
}
.pontosInstall {
  margin-top: 3rem;
  height: 93vh;
  padding-top: 1rem;
  background-color: white;
  text-align: center;
}
.numPontoInstal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
.fontListPontoInstall {
  color: #4d7ec5e6;
  font-weight: bold;
}
.mapaGrafico {
  display: flex;
  margin-top: 0.5rem;
  z-index: 0;
}
.custom {
  width: 100%;
  height: 79vh;
}
.tabs {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 0px;
}
.allScreen {
  display: flex;
  height: 91vh;
  flex-wrap: nowrap;
}
.listBox {
  width: 100%;
  height: 100%;
}

@media only screen and (min-height: 800px) {
  .mmm {
    height: 81vh;
  }

  .pontosInstall {
    height: 95vh;
  }

  .mmm2 {
    height: 54.4vh;
    margin-bottom: 7rem
  }

  .custom {
    height: 84vh;
  }

  .legenda {
    font-size: 25px;
    padding: 1.5rem 0rem;
    margin: 0rem 0rem;
  }

  .espacoLegenda {
    padding: 3.5rem 0rem
  }

  .espacoLegendaSelecionado {
    padding: 2.5rem 0rem !important;
    margin-bottom: .3rem !important;
  }

  #box_font_menor {
    font-size: 25px;
    z-index: 0;
  }

  .contentCodInst {
    font-size: xx-large;
    padding: 0.3rem 2rem;
  }

  #codigoIntalacaoBox {
    width: 19%;
  }

  .cardStyle {
    height: 6.5rem !important;
  }
}

@media only screen and (max-width: 580px) {
  .main_header {
    width: 100%;
  }
  .pontosInstall {
    height: 104vh;
    width: 100%;
    text-align: -webkit-center;
  }

  .allScreen {
    display: none;
  }

  .mapaDisplay {
    display: none;
  }

  .graficoDisplay {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/push-chevron-right.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/push-chevron-left.css');

::v-deep(.box_indicadores) {
  .p-card-body {
    padding: 0.2rem .4rem .5rem .5rem;
  }

  .p-card-content {
    padding: 0rem;
  }

  .p-card-title {
    margin: 0rem;
    font-size: 20px;
    color: black;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    padding-left: 1rem;
  }
}

.cardStyle {
  border-radius: 15px;
  z-index: 0;
  font-size: 20px;
  margin-top: 1rem;
  height: 7.9rem
}

.btn-limpar {
  border-color: #000000;
  background-color: #000000;
  color: white;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

#app {
  height: 100vh;
  background-color: #dee2e6;
}
.gg-push-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, .7));
  width: 10px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-push-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, .7));
  width: 5px;
  height: 11px;
  border: 2px solid transparent;
  border-radius: 100px;
  padding-left: 4.2rem;
  margin-left: -3rem;
}

.openbtn {
  margin-left: -2.6em;
  border: thin;
  scale: 2;
  padding-top: 5rem;
  background: #dee2e6;
  height: 56vh;
}

.openbtn_inicio {
  border: thin;
  scale: 2;
  position: fixed;
  background: #dee2e6;
  margin-top: 0rem;
  height: fit-content;
  padding-right: 0rem;
  width: 1.3rem;
}

.closebtn_inicio {
  border: thin;
  scale: 2;
  position: fixed;
  background: #dee2e6;
  margin-top: 0rem;
  height: fit-content;
  padding-right: 0rem;
}
::v-deep(.p-tabview) {
  .p-tabview-panels {
    border-bottom-right-radius: 3% 7%;
    border-bottom-left-radius: 3% 7%;
    padding: .5rem;
    height: 100%;
    overflow-y: hidden;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6eff94;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6eff94;
  }

  @media only screen and (min-height: 800px) {
    .p-tabview-panels {
      border-bottom-right-radius: 3% 7%;
      border-bottom-left-radius: 3% 7%;
      padding: 1rem;
    }
  }
}
::v-deep(.p-tabview) {
  .p-tabview-nav {
    .p-highlight {
      .p-tabview-nav-link {
        background: #6EFF94;
        border-color: #6EFF94;
        color: #000;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
    }
  }
}

::v-deep(.p-tabview) {
  .p-tabview-nav {
    height: 38px;

    .p-tabview-nav-link {
      height: 38px;
      padding: 0.5rem;

      .p-tabview-title {
        font-weight: 400;
        font-size: 15px;
        color: black;
      }
    }
  }

  @media only screen and (min-height: 800px) {
    .p-tabview-nav {
      height: 65px;

      .p-tabview-nav-link {
        height: 65px;
        padding: 1rem;

        .p-tabview-title {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }
}

::v-deep(.custom) {
  .p-scrollpanel-bar {
    background-color: #6EFF94;
    border: 0 none;
  }
}
::v-deep(.customResultadoAgregado) {
  .p-scrollpanel-bar {
    background-color: #6EFF94;
    border: 0 none;
  }
}

.multiselect {
  width: 95%;
}

::v-deep(.multiselect-custom) {
  .multiselect-caret {
    margin-top: 0rem;
    z-index: 1;
  }
  .multiselect-clear {
    z-index: 1;
  }
}
::v-deep(.p-listbox) {
  .p-listbox-item {
    border-top: 1px solid black;
  }

  .p-disabled,
  .p-disabled * {
    pointer-events: auto;
  }

  .p-listbox-list-wrapper {
    max-height: 100%;
  }

  @media only screen and (min-height: 800px) {
    .p-listbox-list-wrapper {
      max-height: 126%; //filtro fechado
    }
  }
}

::v-deep(.p-multiselect) {
  .p-multiselect-label-container {
    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.sidebar {
  height: 93%;
  width: 0;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  margin-top: 3rem;
  transition: 0.5s;
}

@media only screen and (min-height: 800px) {
  .sidebar {
    height: 95%;
  }

  .listBox {
    height: 58vh;
  }
}

.labelMultiselect {
  float: left;
  margin-left: 0.55rem;
}

.sidebar .closebtn {
  position: absolute;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}
#main {
  transition: margin-left .5s;
  padding: 20px;
}

.device-type-icon {
  width: 30px;
  height: 30px;
}

.device-type-icons-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 60%;
}

.legenda {
  padding: 1.5rem 0rem;
  font-size: 18px;
  margin: 0rem 1rem;
}

.espacoLegenda {
  padding: 0rem 0rem;
  display: flex
}

#box_font_menor {
  font-size: 18px;
  z-index: 0;
  width: 25%;
}

.contentCodInst {
  font-size: 26px;
  padding: 0.3rem 2rem;
}

.estadoConectvidade {
  display: flex;
  padding: .3rem 0rem
}

.levelBat {
  width: 53px;
  height: 36px;
  margin-block: auto;
  margin: 0.3rem 1.5rem;
}

#codigoIntalacaoBox {
  width: 25%;
}

.espacoLegendaSelecionado {
  padding: 1.3rem 0rem;
  margin-bottom: 0rem;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
</style>
<style src="@vueform/slider/themes/default.css">

</style>
