import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import VCalendar from 'v-calendar';
import PrimeVue from 'primevue/config';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueGtag from "vue-gtag";
import mitt from 'mitt'
import {GOOGLE_MAPS_KEY, GOOGLE_ANALYTICS_KEY} from "./services/config";

const emitter = mitt()

createApp(App)
    .use(ToastService)
    .use(PrimeVue)
    .use(VueLoading)
    .use(VCalendar, {})
    .use(router)
    .directive('tooltip', Tooltip)
    .use({
        install(app) {
          app.config.globalProperties.emitter = emitter;
        }
    })
    .use(VueGoogleMaps, {
        load: {
            key: GOOGLE_MAPS_KEY,
        },
    })
    .use(VueGtag, {
        appName: 'turing',
        pageTrackerScreenviewEnabled: true,
        config: { id: GOOGLE_ANALYTICS_KEY },
    }, router)
    .mount('#app');