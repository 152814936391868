import axios from 'axios';
import { API_URL as BASE_URL } from './config';
import { store } from './store.js';

const client = axios.create({
  baseURL: BASE_URL,
  timeout: 15000,
  headers: { 'Authorization': `Bearer ${store.token}` },
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401 && !error.response.config.url.includes('/auth')) {
        window.location.reload();
      } else {
        const apiErrorMessage = error.response.data.message;
        return Promise.reject(apiErrorMessage);
      }
    } else if (error.request) {
      console.error('No response received from server.');
    } else {
      console.error('Error setting up the request:', error.message);
    }
    return Promise.reject(error);
  }
);

export default client;
