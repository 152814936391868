<template>
  <div v-if="testLoad"> 
                <div class="card" style="border-radius:15px">
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6 md:pr-6 pr-0">
                            <div class="custom-skeleton p-4">
                                <Skeleton width="100%" height="250px"></Skeleton>
                                <div class="flex justify-content-center mt-3">
                                    <Skeleton width="100%" height="80px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style="margin-top: 2rem; border-radius:15px">
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6 md:pr-6 pr-0">
                            <div class="custom-skeleton p-4">
                                <Skeleton width="100%" height="250px"></Skeleton>
                                <div class="flex justify-content-center mt-3">
                                    <Skeleton width="100%" height="80px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <div v-else>
        <div id="div1">
          <highcharts
            class="hc"
            :constructorType="'stockChart'"
            :options="chartFlow"
            :redrawOnUpdate="true"
            ref="chart"
          ></highcharts>
        </div>
        <div style="margin-top: 2rem">
          <highcharts
            class="hc"
            :constructorType="'stockChart'"
            :options="chartVolume"
            :redrawOnUpdate="true"
            ref="chart"
          ></highcharts>
        </div>
      </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import {
  devicesDataCalcAveragePerHour,
  devicesDataCalcAccumulated,
} from "../services/dashboard.js";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import highchartsMore from "highcharts/highcharts-more";
import stockInit from "highcharts/modules/stock";
import { useToast } from "primevue/usetoast";
import { store } from '@/services/store.js';
import Skeleton from 'primevue/skeleton';

Highcharts.setOptions({
  lang: {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    weekdays: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    loading: ["Atualizando o gráfico...aguarde"],
    contextButtonTitle: "Exportar gráfico",
    decimalPoint: ",",
    thousandsSep: ".",
    downloadJPEG: "Baixar imagem JPEG",
    downloadPDF: "Baixar arquivo PDF",
    downloadPNG: "Baixar imagem PNG",
    downloadSVG: "Baixar vetor SVG",
    printChart: "Imprimir gráfico",
    rangeSelectorFrom: "De",
    rangeSelectorTo: "Para",
    rangeSelectorZoom: "Zoom",
    resetZoom: "Limpar Zoom",
    resetZoomTitle: "Voltar Zoom para nível 1:1",
  },
});

stockInit(Highcharts);
highchartsMore(Highcharts);
HighchartsNoData(Highcharts);

export default {
  name: "CompResultadoAgregado",
  components: {
    highcharts: Chart,
    Skeleton
  },
  props: {
    data_inicial: String,
    data_final: String,
    device_list: Object,
  },
  data() {
    return {
      testLoad: true,
      widthFlow: null,
      local: null,
      testeaaa:[],
      flagDevice: false,
      list_Devices: [],
      chartVolume: {
        chart: {
          type: "spline",
          zoomType: "xy",
        },
        title: {
          text: "Vazão",
        },
        rangeSelector: {
          selected: 1,
          enabled: true,
        },
        yAxis: {
          title: {
            text: "Vazão",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            type: "column",
            name: "Vazão (m³/h)",
            color: "#6EFF94",
          },
        ],
      },
      chartFlow: {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        title: {
          text: "Volume",
        },
        rangeSelector: {
          selected: 1,
          enabled: true,
        },
        yAxis: {
          title: {
            text: "Volume",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            type: "column",
            name: "Vazão (m³)",
            color: "#6EFF94",
          },
        ],
      },
    };
  },
  watch: {
    flagDevice: function () {
      this.teste();
    },
  },
  methods: {
    teste() {
      this.device_list.map((a) => this.list_Devices.push(a.device_id));
      this.buttonPress();
      this.buttonPress2();
    },
    async buttonPress() {
      this.testLoad = true;
      const data = {
        devices_list: this.list_Devices,
        dti: this.data_inicial,
        dtf: this.data_final,
      };
      await devicesDataCalcAveragePerHour(data)
        .then((result) => {
          this.testLoad = false;
          let auxDate = [];
          let auxValue = [];
          let auxTemp = []
          if (result.data.data[0]) {
            const testeA = Object.entries(result.data.data[0]);
            testeA.map(function ([date, hourValues]) {
              const testeB = Object.entries(hourValues);
              testeB.map(function ([aa, bb]) {
                let aux = `${date} ${aa.slice(5)}:00:00` 
                auxDate.push((Date.parse(aux)))
                 auxValue.push(bb.hourFlowAverage)
                auxTemp.push([(Date.parse(aux)), bb.hourFlowAverage])
              });
            });
            
          }
          this.chartVolume = {
            time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
              type: "column",
              zoomType: "xy",
            },
            title: {
              text: "Volume",
            },
            navigator: {
              enabled: true,
            },
            rangeSelector: {
              enabled: false,
            },
            tooltip: {
              pointFormat: "Vazão: <b>{point.y} m³</b>",
            },
            yAxis: [
              {
                title: {
                  text: "Volume (m³)",
                },
              },
            ],
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            series: [
              {
                type: "column",
                name: "Vazão (m³)",
                data: auxTemp,
                color: "#6EFF94",
              },
            ],
          };
        })
        .catch((err) => {
          this.showErrorFail(err);
          this.testLoad = false;
        });
    },
    async buttonPress2() {
      this.testLoad = true;
      const data = {
        devices_list: this.list_Devices,
        dti: this.data_inicial,
        dtf: this.data_final,
      };
      await devicesDataCalcAccumulated(data)
        .then((result) => {
          this.testLoad = false;
          let auxDate = [];
          let auxValue = [];
          let auxTemp = []
          if (result.data.data[0]) {
            const testeA = Object.entries(result.data.data[0]);
            testeA.map(function ([date, hourValues]) {
              const testeB = Object.entries(hourValues);
              testeB.map(function ([aa, bb]) {
                let aux = `${date} ${aa.slice(5)}:00:00` 
                auxDate.push((Date.parse(aux)))
                 auxValue.push(bb.accumulatedFlowHour)
                auxTemp.push([(Date.parse(aux)), bb.accumulatedFlowHour])
              });
            });
          }

          this.chartFlow = {
           time: {
                timezoneOffset: this.local  * 60,
            },
            chart: {
              type: "spline",
              zoomType: "x",
            },
            title: {
              text: "Vazão",
            },
            navigator: {
              enabled: true,
            },
            rangeSelector: {
              enabled: false,
            },
            tooltip: {
              pointFormat: "Vazão: <b>{point.y} m³/h</b>",
            },
            yAxis: [
              {
                title: {
                  text: "Vazão (m³/h)",
                },
              },
            ],
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        borderRadius: 2,
                        valueDecimals: 2
                    }
                }
            },
            series: [{
                marker: {
                    enabled: true
                },
                name: 'Volume',
                data: auxTemp,
                type: 'spline',
                tooltip: {
                    valueDecimals: 2
                }
            }]
          };
        })
        .catch((err) => {
          this.showErrorFail(err);
          this.testLoad = false;
        });
    },
    getSizeDiv() {
      if(this.$route.path === '/dashboard' && this.checkTypes) {
          this.widthFlow = document.getElementById("div1").getBoundingClientRect().width;
      } 
    },
    onResize() {
      this.getSizeDiv();
      this.chartFlow = {
          chart: {
                  type: "spline",
                  zoomType: "x",
                  width: this.widthFlow,
                },
        };     
        this.chartVolume = {
          chart: {
                  type: "spline",
                  zoomType: "x",
                  width: this.widthFlow,
                },
        };   
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.emitter.on('my-event-controlSidebar', () => {
      this.getSizeDiv()
        this.chartFlow = {
          chart: {
                  type: "spline",
                  zoomType: "x",
                  width: this.widthFlow,
                },
        };     
        this.chartVolume = {
          chart: {
                  type: "spline",
                  zoomType: "x",
                  width: this.widthFlow,
                },
        };    
    })
  },
  mounted() {
    this.flagDevice = true;
    this.local = store.local;
      if(store.summer == true) {
              this.local--
          }
  },
  setup() {
    const toast = useToast();
    const showErrorFail = (data) => {
      toast.add({
        severity: "error",
        summary: data,
        life: 4000,
      });
    };
    return { showErrorFail };
  },
};
</script>

<style>
body {
  background: #f1eeee;
}
.hc {
  position: relative;
  background-color: #fff;
  padding-top: 1rem;
  border-radius: 15px;
}
</style>