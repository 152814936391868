<template>
  <Dialog header="Exportar Relatório" v-model:visible="displayBasic" :style="{ width: '55vw' }" :modal="true"
    :closable="false">
    <div>
      <div style="margin-bottom: 30px">
        <label for="email">Endereço de e-mail</label>
        <input v-for="i in input" :key="i" type="text" class="form-control" :id='"item" + i'
          :placeholder="'Digite o e-mail ' + i" v-model="email[i]" style="margin-bottom: 10px;">
        <div style="float: right">
          <div @click="input++" class="btn btn-pos_add" type="button"><i class="pi pi-plus"> &nbsp;</i>Adicionar</div>
        </div>
      </div>
      <div>
        <p>Formato do arquivo</p>
        <input type="checkbox" id="csv" value="csv" checked v-model="typeFile" />
        <label for="csv" style="margin-right: 20px"> &nbsp;CSV</label>
        <input type="checkbox" id="xlsx" value="xlsx" v-model="typeFile" />
        <label for="xlsx"> &nbsp;XLSX</label>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-pos_dowCancel" @click="limparBusca">Cancelar</button>
      <button class="btn btn-pos_download" @click="flagScreen ? sendRequest() : sendRequestInstallPoints()">
        Enviar
      </button>
    </template>
  </Dialog>

  <Toast />
  <div>
    <div class="btn btn-pos_filtrar" type="button" @click="buttonPress(flagScreen)">
      Exportar Relatório
    </div>
  </div>
</template>

<script>
import { store } from "../services/store";
import { useLoading } from "vue-loading-overlay";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
const $loading = useLoading();

import { reportAll, reportInstallPoints } from "../services/dashboard.js";

export default {
  name: "CompRelatorios",
  emits: ["FiltraRelatorios"],
  components: {
    Toast,
    Dialog,
  },
  props: {
    date_start: String,
    date_end: String,
    typeDevice: String,
    resetRel: Boolean,
    flagScreen: Boolean,
  },
  data() {
    return {
      typeFile: [],
      displayBasic: false,
      device_back: "",
      email: [],
      input: 1,
    };
  },
  watch: {
    resetRel: function () {
      this.limparBusca();
    },
  },


  methods: {
    limparBusca() {
      this.displayBasic = false;
      this.input = 1;
      this.device_back = '';
      this.email = [];
      this.typeFile = [];
    },
    async sendRequest() {
      const loader = $loading.show({});
      const allEmails = this.email.filter(Boolean);

      if (!allEmails.length) {
        loader.hide();
        this.showErrorEmailNotFilled();
        return;
      }

      if (!this.typeFile.length) {
        loader.hide();
        this.showErrorFileNotSelected();
        return;
      }

      let data = {
        start_date: this.date_start,
        end_date: this.date_end,
        device_type: this.typeDevice,
        client_id: store.client_id,
        type_file: this.typeFile,
        email: allEmails,
      };
      await reportAll(data)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.showSuccess(response.data.message);
            loader.hide();
            this.displayBasic = false;
          }
        })
        .catch((error) => {
          console.log(error)
          this.showErrorFail(error.message);
          loader.hide();
          this.displayBasic = false;
        });
    },
    async sendRequestInstallPoints() {
      const loader = $loading.show({});
      const allEmails = this.email.filter(Boolean);

      if (!allEmails.length) {
        loader.hide();
        this.showErrorEmailNotFilled();
        return;
      }

      if (!this.typeFile.length) {
        loader.hide();
        this.showErrorFileNotSelected();
        return;
      }

      const payload = {
        client_id: store.client_id,
        file_types: this.typeFile,
        emails: allEmails,
      };

      await reportInstallPoints(payload)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            this.showSuccess(response.data.message);
            loader.hide();
            this.displayBasic = false;
            this.limparBusca();
          }
        })
        .catch((error) => {
          loader.hide();
          this.showErrorFail(error.message);
          this.displayBasic = false;
        });
    },
    async buttonPress(data) {
      if (data) {
        if (this.typeDevice != null) {
          if (this.typeDevice.length > 0) {
            this.device_back = this.typeDevice;
          }
        }
        this.displayBasic = true;
        if (this.device_back == '') {
          this.showErrorDevice();
          this.displayBasic = false;
        }
      } else {
        this.displayBasic = true;
      }
      const loader = $loading.show({});
      this.email[1] = store.email;
      loader.hide();
    },
  },
  setup() {
    const toast = useToast();
    const showErrorServer = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: " Erro ao acessar o servidor!",
        life: 4000,
      });
    };
    const showErrorDevice = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: " Selecione o tipo do dispositivo! ",
        life: 4000,
      });
    };
    const showErrorDate = () => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: " Selecione um período com no máximo 30 dias! ",
        life: 4000,
      });
    };
    const showErrorFail = (data) => {
      toast.add({
        severity: "error",
        summary: data,
        life: 4000,
      });
    };
    const showSuccess = (data) => {
      toast.add({
        severity: "success",
        summary: data,
        life: 4000,
      });
    };
    const showErrorEmailNotFilled = () => {
      toast.add({
        severity: 'error',
        summary: 'Preencha pelo menos um email válido!',
        life: 4000,
      })
    }

    const showErrorFileNotSelected = () => {
      toast.add({
        severity: 'error',
        summary: 'Selecione o formato do arquivo!',
        life: 4000,
      });
    }

    return {
      showErrorServer,
      showErrorDevice,
      showErrorDate,
      showSuccess,
      showErrorFail,
      showErrorEmailNotFilled,
      showErrorFileNotSelected,
    };
  },
  updated() {
    this.typeFile = [];
  }
};
</script>

<style scoped>
h2 {
  margin: 1rem;
}

.tableBox {
  padding: 1rem 2rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.btn-pos_filtrar {
  border-color: #6eff94;
  background-color: white;
  color: black;
  margin: 1rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

.btn-pos_download {
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 12rem;
  font-weight: 400;
  height: 42px;
}

.btn-pos_dowCancel {
  background-color: #DC3545;
  color: black;
  margin: 1rem 0rem;
  width: 12rem;
  font-weight: 400;
  height: 42px;
}

.btn-pos_add {
  background-color: #858585;
  color: #ffffff;
  margin: 1rem 0rem;
  width: 8rem;
  font-weight: 400;
  height: 42px;
}

.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: 1rem 0rem;
  width: 100%;
  font-weight: 400;
  height: 42px;
}

.inputClass {
  margin: 1rem 0rem;
  width: 100%;
}
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-custom) {
  .multiselect-caret {
    margin-right: -1rem;
  }
}
</style>